import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { ReactNode } from "react";

interface IAuthModal {
  open: boolean;
  title: string;
  description?: string;
  onClickLogin: () => void;
  onClickRegister: () => void;
  onClose: () => void;
  children: ReactNode;
}

export const AuthModal = ({
  open,
  title,
  description,
  children,
  onClose,
  onClickLogin,
  onClickRegister,
}: IAuthModal) => {
  return (
    <Dialog open={open} maxWidth="md" onClose={onClose}>
      <DialogTitle align="center">{title}</DialogTitle>
      <DialogContent
        dividers
        sx={{
          mt: 1,
          mb: 1,
        }}
      >
        {description && (
          <DialogContentText
            sx={{
              fontWeight: 600,
            }}
          >
            {description}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item lg={12} xs={12}>
            <DialogContentText
              sx={{
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Selecione uma das opções abaixo para prosseguir:
            </DialogContentText>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Button
              onClick={onClickLogin}
              fullWidth
              size="large"
              variant="contained"
              color="info"
            >
              Já tenho cadastro
            </Button>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Button
              onClick={onClickRegister}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
            >
              Criar cadastro
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
