import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import { IAssociatedItem, ICategoryHealthPlan, IHealthPlan } from "../../types";
import {
  createUseHealthPlansCategoryItemsKey,
  createUseHealthPlansCategoryKey,
  createUseHealthPlansKey,
} from "./keys";

export const useHealthPlans = (options?: UseQueryOptions<IHealthPlan[]>) => {
  return useQuery(
    createUseHealthPlansKey(),
    () => api.get(`/users-patients/health-plan`).then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      refetchOnWindowFocus: false
    }
  );
};

export const useHealthPlansCategory = (
  { healthPlanId }: { healthPlanId: string },
  options?: UseQueryOptions<ICategoryHealthPlan[]>
) => {
  return useQuery(
    createUseHealthPlansCategoryKey(healthPlanId),
    () =>
      api
        .get(`/users-patients/healthplancategories?idconvenio=${healthPlanId}`)
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      refetchOnWindowFocus: false
    }
  );
};

export const useCategoryHealthPlanItems = (
  {
    healthPlanCategoryId,
    clinicId,
    serviceGroupId,
    searchTerm,
  }: {
    healthPlanCategoryId: string;
    clinicId: string;
    serviceGroupId: string;
    searchTerm: string;
  },
  options?: UseQueryOptions<IAssociatedItem[]>
) => {
  return useQuery(
    createUseHealthPlansCategoryItemsKey(
      healthPlanCategoryId,
      clinicId,
      serviceGroupId,
      searchTerm
    ),
    () =>
      api
        .get(
          `/users-patients/servicehealthplancategories?health_plan_category_id=${healthPlanCategoryId}&idclinica=${clinicId}&idgruposervico=${serviceGroupId}&specialties=${searchTerm}`
        )
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      refetchOnWindowFocus: false
    }
  );
};
