import {
  Alert,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { CaretDown, CaretUp, Check } from "phosphor-react";
import { useState } from "react";
import { useAppointment } from "../../hooks/useAppointment";
import { IServicePackage } from "../../types";
import { formatCurrency, formatName } from "../../utils/masks";

interface IServicePackageCard {
  servicePackageItem: IServicePackage;
}

export const ServicePackageCard = ({
  servicePackageItem,
}: IServicePackageCard) => {
  const [isPromotionalItemsShow, setIsPromotionalItemsShow] = useState(false);

  const {
    handleChangeAppointmentServicePackage,
    servicePackage,
    handleChangeAppointmentStep,
  } = useAppointment();

  const theme = useTheme();

  const handleSelectServicePackage = async () => {
    handleChangeAppointmentServicePackage(servicePackageItem);
    handleChangeAppointmentStep(1);

    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }, 300);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        borderColor:
          servicePackage &&
          servicePackage.idcombo === servicePackageItem.idcombo
            ? theme.palette.primary.main
            : theme.palette.neutral.main,
        transition: theme.transitions.create(["border"], {
          duration: theme.transitions.duration.standard,
        }),
        height: "100%",
      }}
    >
      <CardContent>
        <Grid container spacing={2} mb={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              fontSize={16}
              sx={{
                color: (theme) => theme.palette.onPrimaryContainer.main,
                fontWeight: 500,
              }}
            >
              {formatName(servicePackageItem.titulo)}
            </Typography>
          </Grid>
        </Grid>

        {servicePackageItem.descricao && (
          <Grid container spacing={2} mb={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                fontSize={12}
                sx={{
                  color: (theme) => theme.palette.onSurfaceVariant.main,
                  fontWeight: 400,
                }}
              >
                {servicePackageItem.descricao}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} mb={2}>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
            display="flex"
            alignItems="center"
          >
            <Typography
              fontSize={16}
              sx={{
                color: (theme) => theme.palette.onSurfaceVariant.main,
                fontWeight: 600,
              }}
            >
              {formatCurrency(Number(servicePackageItem.valor))}
            </Typography>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Button
              endIcon={isPromotionalItemsShow ? <CaretUp /> : <CaretDown />}
              size="small"
              onClick={() => setIsPromotionalItemsShow((oldValue) => !oldValue)}
            >
              Detalhes
            </Button>
          </Grid>
          {servicePackageItem.tipospagamentos && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Alert color="info">{servicePackageItem.tipospagamentos}</Alert>
            </Grid>
          )}
        </Grid>
        <Collapse in={isPromotionalItemsShow}>
          <Grid container spacing={2} mb={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            {servicePackageItem.itenspromocionais.map((item) => (
              <Grid
                key={item.iditempromocional}
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Check color={theme.palette.primary.main} />
                  <Typography
                    fontSize={14}
                    sx={{
                      color: (theme) => theme.palette.onSurfaceVariant.main,
                      fontWeight: 400,
                    }}
                  >
                    {formatName(item.itemservico.nome)}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Collapse>

        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              fullWidth
              variant="contained"
              size="medium"
              onClick={() => handleSelectServicePackage()}
            >
              Selecionar combo
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
