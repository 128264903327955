import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { X } from "phosphor-react";
import { useState } from "react";
import { useAppointment } from "../../hooks/useAppointment";
import { useHealthPlans, useHealthPlansCategory } from "../../store/healthPlan";
import { ICategoryHealthPlan, IHealthPlan } from "../../types";

interface IHealthPlanModal {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const HealthPlanModal = ({
  open,
  onClose,
  onConfirm,
}: IHealthPlanModal) => {
  const {
    appointmentType,
    healthPlan,
    handleChangeAppointmentType,
    handleChangeHealthPlan,
    handleChangeCategoryHealthPlan,
    handleChangeAppointmentServiceItem,
    handleChangeServiceItemHealtPlan,
    categoryHealthPlan,
  } = useAppointment();

  const [healthPlanSelected, setHealthPlanSelected] =
    useState<IHealthPlan | null>(healthPlan);
  const [categoryHealthPlanSelected, setCategoryHealthPlanSelected] =
    useState<ICategoryHealthPlan | null>(categoryHealthPlan);

  const { data: healthPlans, isLoading: isLoadingHealthPlans } =
    useHealthPlans();
  const { data: healthPlansCategory, isLoading: isLoadingHealthPlansCategory } =
    useHealthPlansCategory(
      {
        healthPlanId: healthPlanSelected ? healthPlanSelected.idconvenio : "",
      },
      {
        enabled: !!healthPlanSelected,
      }
    );

  const handleSubmit = () => {
    handleChangeHealthPlan(healthPlanSelected);
    handleChangeCategoryHealthPlan(categoryHealthPlanSelected);
    // handleChangeAppointmentServiceItem(null);
    // handleChangeServiceItemHealtPlan(null);
    onConfirm();
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
      <DialogTitle
        align="center"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.onPrimary.main,
        }}
      >
        O agendamento será por Convênio ou Particular?
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.onPrimary.main,
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          mt: 1,
          mb: 1,
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          display="flex"
          mt={2}
        >
          <Grid item lg={10} md={12} sm={12} xs={12}>
            <Alert color="warning">Selecione um opção para continuar</Alert>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          display="flex"
          mt={2}
          spacing={2}
        >
          <Grid item lg={10} md={12} sm={12} xs={12}>
            <Card variant="outlined">
              <ListItemButton
                selected={appointmentType === "particular"}
                role={undefined}
                onClick={() => {
                  handleChangeAppointmentType("particular");
                  handleChangeAppointmentServiceItem(null);
                  handleChangeServiceItemHealtPlan(null);
                }}
                sx={{
                  borderWidth: 1,
                }}
              >
                <ListItemIcon>
                  <Radio
                    edge="start"
                    checked={appointmentType === "particular"}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary="Particular" />
              </ListItemButton>
            </Card>
          </Grid>
          <Grid item lg={10} md={12} sm={12} xs={12}>
            <Card variant="outlined">
              <ListItemButton
                selected={appointmentType === "convenio"}
                role={undefined}
                onClick={() => {
                  handleChangeAppointmentType("convenio");
                  setHealthPlanSelected(null);
                  setCategoryHealthPlanSelected(null);
                  handleChangeHealthPlan(null);
                  handleChangeCategoryHealthPlan(null);
                  handleChangeAppointmentServiceItem(null);
                  handleChangeServiceItemHealtPlan(null);
                }}
                sx={{
                  borderWidth: 1,
                }}
              >
                <ListItemIcon>
                  <Radio
                    edge="start"
                    checked={appointmentType === "convenio"}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary="Convênio" />
              </ListItemButton>
            </Card>
          </Grid>
        </Grid>
        <Collapse in={appointmentType === "convenio"}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
            spacing={2}
          >
            <Grid item lg={10} md={12} sm={12} xs={12} mt={4}>
              <Card
                sx={{
                  backgroundColor: "rgba(221,227,234,0.2)",
                  height: "100%",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography>Escolha qual o convênio e plano</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Autocomplete
                        id="healthPlans"
                        options={healthPlans ? healthPlans : []}
                        getOptionLabel={(value) => value.nome.toUpperCase()}
                        disabled={isLoadingHealthPlans}
                        loading={isLoadingHealthPlans}
                        onChange={(event, newValue) => {
                          setHealthPlanSelected(newValue);
                        }}
                        value={healthPlanSelected || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Selecione um convênio"
                            label="Convênio atendidos"
                            variant="outlined"
                            size="medium"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Autocomplete
                        id="healthPlansCategory"
                        options={healthPlansCategory ? healthPlansCategory : []}
                        getOptionLabel={(value) => value.nome.toUpperCase()}
                        disabled={
                          isLoadingHealthPlansCategory || !healthPlanSelected
                        }
                        loading={isLoadingHealthPlansCategory}
                        onChange={(event, newValue) => {
                          setCategoryHealthPlanSelected(newValue);
                        }}
                        value={categoryHealthPlanSelected || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Selecione o seu plano"
                            label="Planos de saúde"
                            variant="outlined"
                            size="medium"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Collapse>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          display="flex"
          spacing={2}
        >
          <Grid item lg={10} md={12} sm={12} xs={12} mt={4}>
            {appointmentType !== null && (
              <Button
                onClick={() => handleSubmit()}
                fullWidth
                disabled={
                  appointmentType === "convenio" && !categoryHealthPlanSelected
                }
                variant="contained"
                size="large"
              >
                Salvar e continuar
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
