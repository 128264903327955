import { QueryKey } from "react-query";

export const createUseServicePackageKey = ({
  clinicId,
}: {
  clinicId: string;
}): QueryKey => ["useServicePackage", clinicId];

export const createUseServicePackageByIdKey = ({
  servicePackageId,
}: {
  servicePackageId: string;
}): QueryKey => ["useServicePackageById", servicePackageId];

export const createUseServicePackageByPatientIdKey = ({
  patientId,
}: {
  patientId: string;
}): QueryKey => ["useServicePackageByPatientId", patientId];
