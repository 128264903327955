import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";
import LogoTextWhite from "../../assets/logo-white.png";
import LogoText from "../../assets/logo.png";
import config from "../../config";
import { useAppointmentWebSetup } from "../../store/setup";
// ==============================|| MAIN LOGO ||============================== //

interface ILogoSection {
  dark?: boolean;
  mini?: boolean;
}

const LogoSection = ({ dark = false, mini = false }: ILogoSection) => {
  const { data: setup } = useAppointmentWebSetup();

  return (
    <ButtonBase
      sx={{
        my: 2,
      }}
      disableRipple
      component={Link}
      to={
        setup && setup.configs.general.url_site
          ? setup.configs.general.url_site
          : config.defaultPath
      }
    >
      <img
        src={
          setup && setup.configs.general.url_logo
            ? setup.configs.general.url_logo
            : process.env.REACT_APP_FAVICON_URL
            ? process.env.REACT_APP_FAVICON_URL
            : dark === true
            ? LogoText
            : LogoTextWhite
        }
        // width="200"
        height="45"
        alt={process.env.REACT_APP_WEBSITE_NAME || "Logo clínica"}
        draggable="false"
      />
    </ButtonBase>
  );
};

export default LogoSection;
