import {
  Card,
  CardContent,
  Divider,
  Grid,
  Skeleton,
  Stack,
  useTheme,
} from "@mui/material";

export const ProfessionalScheduleCardSkeleton = () => {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        borderColor: theme.palette.neutral.main,
        borderWidth: 1,
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            lg={1}
            md={1}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Skeleton variant="circular" width={56} height={56} />
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <Skeleton variant="text" sx={{ fontSize: "2.4rem", width: 300 }} />

            <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: 100 }} />

            <Skeleton variant="text" sx={{ fontSize: "1.2rem", width: 200 }} />
            <Stack mt={2}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1.2rem", width: 450 }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Divider
          sx={{
            mt: 2,
            mb: 2,
          }}
        />

        <Stack spacing={2} direction="row">
          <Skeleton variant="rounded" width={120} height={42} />
          <Skeleton variant="rounded" width={120} height={42} />
          <Skeleton variant="rounded" width={120} height={42} />
          <Skeleton variant="rounded" width={120} height={42} />
          <Skeleton variant="rounded" width={120} height={42} />
          <Skeleton variant="rounded" width={120} height={42} />
        </Stack>
      </CardContent>
    </Card>
  );
};
