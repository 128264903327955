import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useAuthenticatedUser } from "../../store/user";
import LoaderProgress from "../LoaderProgress";

interface IProtectedRoutes {
  children: any;
}

export const ProtectedRoute = ({ children }: IProtectedRoutes) => {
  const { isAuthenticated } = useAuth();

  const isToken = isAuthenticated();

  const { isLoading, data } = useAuthenticatedUser({
    enabled: isToken,
  });
  const location = useLocation();
  const state: any = location.state;

  if (!isToken) {
    if (state) {
      if (state.login) {
        return (
          <Navigate
            to={`/login?redirect=${location.pathname}`}
            replace
            state={{ from: location }}
          />
        );
      } else {
        return (
          <Navigate
            to={`/cadastro?redirect=${location.pathname}`}
            replace
            state={{ from: location }}
          />
        );
      }
    }
    return (
      <Navigate
        to={`/login?redirect=${location.pathname}`}
        replace
        state={{ from: location }}
      />
    );
  }

  if (!isLoading) {
    if (!data) {
      if (state) {
        if (state.login) {
          return (
            <Navigate
              to={`/login?redirect=${location.pathname}`}
              replace
              state={{ from: location }}
            />
          );
        } else {
          return (
            <Navigate
              to={`/cadastro?redirect=${location.pathname}`}
              replace
              state={{ from: location }}
            />
          );
        }
      }
      return (
        <Navigate
          to={`/login?redirect=${location.pathname}`}
          replace
          state={{ from: location }}
        />
      );
    }

    return children;
  }
  return <LoaderProgress />;
};
