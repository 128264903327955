import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setDefaultOptions } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AppointmentProvider } from "./contexts/AppointmentContext";
import { AuthProvider } from "./contexts/AuthContext";
import { CustomizationProvider } from "./contexts/CustomizationContext";
import { WithAxios } from "./contexts/WithAxios";
import { NavigationScroll } from "./layout/NavigationScroll";
import { AppRoutes } from "./routes";
import { theme } from "./theme";

const queryClient = new QueryClient();
setDefaultOptions({ locale: ptBR });

function App() {
  useEffect(() => {
    console.log("Initializing Google Analytics");
    // console.log(process.env.GA_KEY)
    // if (process.env.GA_KEY)
    ReactGA.initialize("UA-156031486-3");
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <CustomizationProvider>
            <AppointmentProvider>
              <ThemeProvider theme={theme}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={ptBR}
                >
                  <ToastContainer
                    // position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover={false}
                    theme="light"
                  />
                  <WithAxios>
                    <NavigationScroll>
                      <AppRoutes />
                    </NavigationScroll>
                  </WithAxios>
                </LocalizationProvider>
              </ThemeProvider>
            </AppointmentProvider>
          </CustomizationProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
