import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ImgPrancheta from "../../assets/images/prancheta.png";
import { useAppointmentWebSetup } from "../../store/setup";

interface IAppointmentAdsCard {
  title?: string;
  link?: string;
}

const AppointmentAdsCard = ({ link, title }: IAppointmentAdsCard) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));
  const { data: setup } = useAppointmentWebSetup();

  return (
    <Card
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item lg={1} md={1} sm={1} xs={3}>
            <img
              src={
                setup && setup.configs.appointment.url_image_card
                  ? setup.configs.appointment.url_image_card
                  : ImgPrancheta
              }
              alt=""
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid
            item
            lg={11}
            md={11}
            sm={11}
            xs={9}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {title ? (
              <Typography
                component="h1"
                variant="h3"
                sx={{
                  color: (theme) => theme.palette.onPrimary.main,
                  fontSize: matchesSM ? 16 : 24,
                  fontWeight: 600,
                }}
              >
                {title}
              </Typography>
            ) : setup && setup.configs.appointment.text_appointment_card ? (
              <Typography
                component="h1"
                variant="h3"
                sx={{
                  color: (theme) => theme.palette.onPrimary.main,
                  fontSize: matchesSM ? 16 : 24,
                  fontWeight: 600,
                }}
              >
                {setup.configs.appointment.text_appointment_card}
              </Typography>
            ) : (
              <Typography
                component="h1"
                variant="h3"
                sx={{
                  color: (theme) => theme.palette.onPrimary.main,
                  fontSize: matchesSM ? 16 : 24,
                  fontWeight: 600,
                }}
              >
                Agendamento Online{" "}
                {process.env.REACT_APP_WEBSITE_NAME
                  ? process.env.REACT_APP_WEBSITE_NAME
                  : ""}{" "}
                24h a seu dispor.
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentAdsCard;
