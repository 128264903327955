import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  StepIcon,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { X } from "phosphor-react";
import { useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../services/api";
import {
  useAuthenticatedUser,
  useCreateDependent,
  useUpdateDependent,
} from "../../store/user";
import { PatientType } from "../../types";
import { maskCpf, maskPhoneNumber, removeMaskCpf } from "../../utils/masks";
import { validateCpf } from "../../utils/roles";

interface IRegisterDependentModal {
  open: boolean;
  onClose: () => void;
}

const USER_DOCUMENT_MAX_LENGTH = 11;
const PHONE_NUMBER_MAX_LENGTH = 11;

export const RegisterDependentModal = ({
  onClose,
  open,
}: IRegisterDependentModal) => {
  const { data: user } = useAuthenticatedUser();
  const [cpfIsValid, setCpfIsValid] = useState<boolean>(false);
  const [patientExists, setPatientExists] = useState<PatientType | null>(null);

  // data register
  const [name, setName] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);

  // errors messages
  const [nameError, setNameError] = useState<string>(" ");
  const [cpfError, setCpfError] = useState<string>(" ");
  const [phoneError, setPhoneError] = useState<string>(" ");
  const [birthDateError, setBirthDateError] = useState<string>(" ");
  const [emailError, setEmailError] = useState<string>(" ");
  const [genderError, setGenderError] = useState<string>(" ");

  const createDependentMutation = useCreateDependent();
  const updateDependentMutation = useUpdateDependent();

  const onChangeUserDocument = (value: string) => {
    value = removeMaskCpf(value);
    const regex = /^([0-9.-]+)+$/;
    if (value.length > USER_DOCUMENT_MAX_LENGTH) {
      return;
    }
    setCpf(value);
    setCpfError(" ");

    if (!value) {
      return setCpfError("O CPF é obrigatório");
    }

    if (!validateCpf(value)) {
      return setCpfError("O CPF é inválido");
    }

    if (!regex.test(value)) {
      return setCpfError("Revise este dado");
    }
  };

  const onChangePhoneNumber = (value: string) => {
    value = removeMaskCpf(value);
    const regex = /^([0-9.-]+)+$/;
    if (value.length > PHONE_NUMBER_MAX_LENGTH) {
      return;
    }
    setPhone(value);
    setPhoneError(" ");

    if (!value) {
      return setPhoneError("O número de telefone é obrigatório");
    }

    if (!regex.test(value)) {
      return setPhoneError("Revise este dado");
    }
  };

  const handleValidateDocument = async () => {
    setCpfError(" ");
    if (!cpf) {
      return setCpfIsValid(false);
    }
    if (!validateCpf(cpf)) {
      return setCpfError("Digite um CPF válido");
    }
    setProcessing(true);

    try {
      const res = await api.get(`/bots/patients-search?search=${cpf}`);
      if (res.data.data.patients.length > 0) {
        setPatientExists(res.data.data.patients[0]);
        setName(res.data.data.patients[0].nomepaciente);
      }

      setProcessing(false);
      setCpfIsValid(true);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      }
      setProcessing(false);
    }
  };

  const handleSubmitRegister = async () => {
    setProcessing(true);
    setNameError(" ");
    setCpfError(" ");

    if (patientExists) {
      setProcessing(true);

      updateDependentMutation.mutate(
        {
          cpf: patientExists?.cpf,
          cpfresponsavel: user?.paciente.cpf,
          telefonecelular: patientExists?.telefonecelular,
          nomeresponsavel: user?.paciente.nomepaciente,
        },
        {
          onSuccess(data, variables, context) {
            setPatientExists(null);
            setCpf("");
            setName("");
            setCpfIsValid(false);
            setProcessing(false);
            onClose();
          },
          onError(error, variables, context) {
            setPatientExists(null);
            setCpf("");
            setName("");
            setCpfIsValid(false);
            setProcessing(false);
          },
        }
      );
      // put
      // return await updateDependent();
    }

    if (email === "") {
      setEmailError("O e-mail é obrigatório");
      setProcessing(false);
      return;
    }
    if (cpf === "") {
      setCpfError("O CPF é obrigatório");
      setProcessing(false);
      return;
    }
    if (!validateCpf(cpf)) {
      setCpfError("Digite um CPF válido");
      setProcessing(false);
      return;
    }
    if (birthDate === null) {
      setBirthDateError("A data de nascimento é inválida");
      setProcessing(false);
      return;
    }
    if (email === "") {
      setEmailError("O e-mail é obrigatório");
      setProcessing(false);
      return;
    }
    if (gender === "") {
      setGenderError("Selecione uma opção");
      setProcessing(false);
      return;
    }

    createDependentMutation.mutate(
      {
        dependent: {
          nomepaciente: name,
          sexo: gender,
          cpf: cpf,
          datanascimento: birthDate
            ? format(birthDate, "yyyy-MM-dd")
            : birthDate,
          telefonecelular: phone,
          telefoneresidencial: "",
          descricaopacientefonte: "Agendamento web",
          email: email,
          cpfresponsavel: user?.paciente.cpf,
          nomeresponsavel: user?.paciente.nomepaciente,
        },
      },
      {
        onSuccess: () => {
          console.log("Cadastro realizado com sucesso");
          setPatientExists(null);
          setProcessing(false);
          onClose();
        },
      }
    );
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
      <DialogTitle align="center">
        Cadastro de um dependente
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <X />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{
          mt: 1,
          mb: 1,
        }}
      >
        {!(cpfIsValid && patientExists === null) && (
          <Box width="100%">
            {cpfIsValid && patientExists !== null && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                display="flex"
                mt={2}
              >
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Alert color="success" icon={false}>
                    Encontramos uma pessoa já cadastrada. Deseja vincular como
                    dependente?
                  </Alert>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              display="flex"
              mt={2}
            >
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <StepIcon icon="1" active />
                          <Typography
                            component="h1"
                            variant="h6"
                            fontWeight={600}
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                              fontSize: 18,
                            }}
                          >
                            Dados pessoais
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          variant="outlined"
                          size="medium"
                          placeholder="Digite o CPF do seu dependente"
                          label="CPF"
                          fullWidth
                          disabled={cpfIsValid}
                          InputProps={{
                            endAdornment: processing ? (
                              <InputAdornment position="end">
                                <CircularProgress color="inherit" size={24} />
                              </InputAdornment>
                            ) : null,
                          }}
                          value={maskCpf(cpf)}
                          error={cpfError !== " "}
                          helperText={cpfError}
                          type="tel"
                          onChange={(e) => onChangeUserDocument(e.target.value)}
                        />
                      </Grid>

                      {cpfIsValid && patientExists !== null && (
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={4}>
                          <TextField
                            variant="outlined"
                            size="medium"
                            placeholder="Digite seu nome completo"
                            label="Nome completo"
                            fullWidth
                            value={name}
                            disabled={true}
                            type="text"
                          />
                        </Grid>
                      )}

                      {cpfIsValid && patientExists !== null ? (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            onClick={() => handleSubmitRegister()}
                            fullWidth
                            disabled={createDependentMutation.isLoading}
                            variant="contained"
                            size="large"
                          >
                            Clique aqui para vincular
                          </Button>
                        </Grid>
                      ) : (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            onClick={() => handleValidateDocument()}
                            fullWidth
                            disabled={updateDependentMutation.isLoading}
                            variant="contained"
                            size="large"
                          >
                            Clique aqui para continuar
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}

        {cpfIsValid && patientExists === null && (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              display="flex"
              mt={2}
            >
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <StepIcon icon="1" active />
                          <Typography
                            component="h1"
                            variant="h6"
                            fontWeight={600}
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                              fontSize: 18,
                            }}
                          >
                            Dados pessoais
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography
                          component="h1"
                          variant="h6"
                          fontWeight={400}
                          sx={{
                            color: (theme) => theme.palette.text.disabled,
                            fontSize: 14,
                          }}
                        >
                          Preencha com os dados do seu dependente
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                        <TextField
                          variant="outlined"
                          size="medium"
                          placeholder="Digite seu nome completo"
                          label="Nome completo"
                          fullWidth
                          value={name}
                          error={nameError !== " "}
                          helperText={nameError}
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          variant="outlined"
                          size="medium"
                          placeholder="Digite seu CPF"
                          label="CPF"
                          fullWidth
                          disabled={cpfIsValid && patientExists === null}
                          value={maskCpf(cpf)}
                          type="tel"
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <DatePicker
                          label="Data de nascimento"
                          onChange={(newValue) => setBirthDate(newValue)}
                          value={birthDate}
                          disableFuture
                          sx={{
                            width: "100%",
                          }}
                        />
                        {birthDateError !== " " && (
                          <FormHelperText error>
                            {birthDateError}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Gênero
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            onChange={(e, value) => setGender(value)}
                          >
                            <FormControlLabel
                              value="Masculino"
                              control={<Radio />}
                              label="Masculino"
                            />
                            <FormControlLabel
                              value="Feminino"
                              control={<Radio />}
                              label="Feminino"
                            />
                          </RadioGroup>
                          {genderError !== " " && (
                            <FormHelperText error>{genderError}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              display="flex"
              mt={2}
            >
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <StepIcon icon="2" />
                          <Typography
                            component="h1"
                            variant="h6"
                            fontWeight={600}
                            sx={{
                              color: (theme) => theme.palette.text.primary,
                              fontSize: 18,
                            }}
                          >
                            Contato
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12} mt={4}>
                        <TextField
                          id="email"
                          name="email"
                          label="E-mail"
                          size="medium"
                          placeholder="Digite seu e-mail"
                          fullWidth
                          value={email}
                          error={emailError !== " "}
                          helperText={emailError}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          id="phoneNumber"
                          name="phoneNumber"
                          label="Número de telefone"
                          size="medium"
                          placeholder="(91) 00000-0000"
                          fullWidth
                          value={maskPhoneNumber(phone)}
                          type="tel"
                          onChange={(e) => onChangePhoneNumber(e.target.value)}
                          error={phoneError !== " "}
                          helperText={phoneError}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              display="flex"
              mt={2}
            >
              <Grid item lg={6} md={8} sm={12} xs={12}>
                <Button
                  onClick={() => handleSubmitRegister()}
                  fullWidth
                  disabled={createDependentMutation.isLoading}
                  variant="contained"
                  size="large"
                >
                  Clique aqui para continuar
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
