import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import { IServiceItem } from "../../types";
import { createUseServiceItemKey } from "./keys";

export const useServiceItem = (
  {
    clinicId,
    searchTerm,
    serviceGroupId,
  }: {
    serviceGroupId: string;
    searchTerm: string;
    clinicId: string;
  },
  options?: UseQueryOptions<IServiceItem[]>
) => {
  return useQuery(
    createUseServiceItemKey({
      clinicId,
      searchTerm,
      serviceGroupId,
    }),
    () =>
      api
        .get(
          `/users-patients/especialidade?idgruposervico=${serviceGroupId}&idclinica=${clinicId}&specialties=${searchTerm}`
        )
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      refetchOnWindowFocus: false
    }
  );
};
