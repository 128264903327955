import { Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import { Calendar } from "phosphor-react";
import { getDaysDiffInWords } from "../../utils/masks";

interface IDateContainer {
  isActive: boolean;
  disabled?: boolean;
  isCalendar?: boolean;
  date: Date;
  onClick: () => void;
}

export const DateContainer = ({
  isActive,
  date,
  isCalendar = false,
  onClick,
  disabled = false,
}: IDateContainer) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card
      variant="outlined"
      component="button"
      disabled={disabled}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      sx={{
        borderColor: isActive
          ? theme.palette.primary.main
          : theme.palette.neutral.main,
        backgroundColor: theme.palette.background.default,
        opacity: disabled ? 0.5 : 1,
        borderWidth: 1,
        transition: theme.transitions.create(["transform"], {
          duration: theme.transitions.duration.standard,
        }),
        width: "100%",
        height: matchesSM ? 75 : 100,
        cursor: disabled ? "not-allowed" : "pointer",
        "&:hover": {
          transform: disabled ? "none" : "scale(1.05)",
          zIndex: 10,
        },
      }}
    >
      {isCalendar ? (
        <Calendar size={matchesSM ? 16 : 24} />
      ) : (
        <Typography
          sx={{
            color: (theme) => theme.palette.text.primary,
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          {format(date, "iiiiii")}
        </Typography>
      )}
      <Typography
        sx={{
          color: (theme) => theme.palette.text.primary,
          fontSize: matchesSM ? 12 : 16,
          width: "100%",
          fontWeight: 600,
        }}
      >
        {isCalendar
          ? `outro dia`
          : `${format(date, "dd")}/${getDaysDiffInWords(date)}`}
      </Typography>
    </Card>
  );
};
