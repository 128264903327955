import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Footer } from "../../../components/Footer";
import { HomeNavbar } from "../../Home/components/HomeNavbar";

import { format } from "date-fns";
import { Params, useNavigate, useParams } from "react-router-dom";
import { useAppointmentById } from "../../../store/appointment";
import {
  formatClinicAddress,
  formatCurrency,
  formatName,
} from "../../../utils/masks";
import { singularizeWithArticle } from "../../../utils/roles";

interface ISummaryParams extends Params {
  appointmentId: string;
}

export default function Summary() {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));
  const { appointmentId } = useParams<ISummaryParams>();

  const navigate = useNavigate();

  const { data: appointment } = useAppointmentById(
    {
      appointmentId: appointmentId ? appointmentId : "",
    },
    {
      enabled: !!appointmentId,
    }
  );

  const appointmentedServiceItem =
    appointment && appointment.orderitems.length > 0
      ? appointment.orderitems[0]
      : null;

  const goToAppointmentPage = () => {
    return navigate("/", { replace: true });
  };

  const goToDashboard = () => {
    return navigate("/paciente/dashboard", { replace: true });
  };

  const serviceGroup = appointment
    ? appointment.orderitems.length > 0 &&
      appointment.orderitems[0].itemservicos.gruposervico
      ? appointment.orderitems[0].itemservicos.gruposervico
      : appointment.orderitems[0].itemservicos.categoriaservico.gruposervico
      ? appointment.orderitems[0].itemservicos.categoriaservico.gruposervico
      : null
    : null;

  return (
    <Box
      minHeight="100vh"
      id="scroll-container"
      position="relative"
      sx={{
        pb: 2,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <HomeNavbar dark={true} />
      <LinearProgress color="secondary" variant="determinate" value={100} />
      <Box>
        <Container
          maxWidth="lg"
          sx={{
            minHeight: matchesSM ? "calc(100vh - 200px)" : "calc(90vh - 200px)",
          }}
        >
          {appointment && serviceGroup && (
            <Grid
              container
              sx={{ py: 3, px: 1 }}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                    >
                      <Grid
                        item
                        lg={10}
                        md={10}
                        sm={12}
                        xs={12}
                        textAlign="center"
                        mb={2}
                      >
                        <Typography
                          fontSize={22}
                          sx={{
                            color: (theme) => theme.palette.primary.main,
                            fontWeight: 600,
                          }}
                        >
                          {appointment.paciente.nomepaciente}, seu agendamento
                          foi realizado com sucesso.
                        </Typography>
                      </Grid>
                      <Grid item lg={10} md={10} xs={12} textAlign="center">
                        <Typography
                          fontSize={14}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                          }}
                        >
                          {`${
                            appointment.profissional.sexo.toLowerCase() ===
                            "masculino"
                              ? `O`
                              : `A`
                          } profissional ${formatName(
                            appointment.profissional.nome
                          )} já recebeu o agendamento d${singularizeWithArticle(
                            serviceGroup.nome.toLowerCase()
                          )} e
                          espera por você na clínica ${formatName(
                            appointment.agenda.clinica.nome
                          )}.`}
                        </Typography>
                      </Grid>

                      <Grid item lg={10} md={10} xs={12} mt={2}>
                        <Card
                          sx={{
                            backgroundColor: "rgba(221,227,234,0.2)",
                            height: "100%",
                          }}
                        >
                          <CardContent>
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                                fontWeight: 600,
                                mb: 2,
                                textAlign: "center",
                              }}
                            >
                              Detalhes do agendamento
                            </Typography>
                            <Grid container mt={3}>
                              {appointmentedServiceItem && serviceGroup && (
                                <>
                                  <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      fontSize={16}
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.onPrimaryContainer.main,
                                        fontWeight: 500,
                                        mb: 1,
                                      }}
                                    >
                                      Especialidade:{" "}
                                      <b>
                                        {
                                          appointmentedServiceItem.itemservicos
                                            .nome
                                        }
                                      </b>
                                    </Typography>
                                    <Alert color="info">
                                      <AlertTitle>
                                        Valor d
                                        {singularizeWithArticle(
                                          serviceGroup.nome.toLowerCase()
                                        )}
                                        :{" "}
                                        {formatCurrency(
                                          Number(appointment.valoratual)
                                        )}
                                      </AlertTitle>
                                      {serviceGroup && (
                                        <>
                                          {serviceGroup.tipospagamentos !==
                                            "" && serviceGroup.tipospagamentos}
                                        </>
                                      )}
                                    </Alert>
                                  </Grid>

                                  {appointmentedServiceItem.itemservicos
                                    .preparo !== "" && (
                                    <>
                                      <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        mt={2}
                                      >
                                        <Typography
                                          fontSize={16}
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.onPrimaryContainer
                                                .main,
                                            fontWeight: 500,
                                            mb: 1,
                                          }}
                                        >
                                          Preparo para{" "}
                                          {singularizeWithArticle(
                                            serviceGroup.nome.toLowerCase()
                                          )}
                                        </Typography>
                                        <Alert color="warning" icon={false}>
                                          {
                                            appointmentedServiceItem
                                              .itemservicos.preparo
                                          }
                                        </Alert>
                                      </Grid>
                                    </>
                                  )}

                                  {appointment.agenda.clinica &&
                                    appointment.agenda.clinica.info_paciente !==
                                      "" && (
                                      <>
                                        <Grid
                                          item
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          mt={2}
                                        >
                                          <Typography
                                            fontSize={16}
                                            sx={{
                                              color: (theme) =>
                                                theme.palette.onPrimaryContainer
                                                  .main,
                                              fontWeight: 500,
                                              mb: 1,
                                            }}
                                          >
                                            Informações ao paciente
                                          </Typography>
                                          <Alert color="error" icon={false}>
                                            {
                                              appointment.agenda.clinica
                                                .info_paciente
                                            }
                                          </Alert>
                                        </Grid>
                                      </>
                                    )}
                                </>
                              )}
                            </Grid>
                            <Divider
                              sx={{
                                width: "100%",
                                mt: 2,
                              }}
                            />
                            <Grid container spacing={2}>
                              {appointment.agenda.clinica && (
                                <>
                                  <Grid item lg={10} md={10} sm={12} xs={12}>
                                    <Stack mt={2}>
                                      <Typography
                                        fontSize={14}
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.onPrimaryContainer
                                              .main,
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>Clínica:</b>{" "}
                                        {appointment.agenda.clinica.nome}
                                      </Typography>
                                      <Typography
                                        fontSize={14}
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.onPrimaryContainer
                                              .main,
                                          fontWeight: 500,
                                        }}
                                      >
                                        <b>Endereço:</b>{" "}
                                        {formatClinicAddress(
                                          appointment.agenda.clinica
                                        )}
                                      </Typography>
                                    </Stack>
                                    <Stack mt={2}>
                                      {!appointment.agenda.type_vacancy ? (
                                        <Typography
                                          fontSize={14}
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.onPrimaryContainer
                                                .main,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`Data e hora: ${format(
                                            new Date(appointment.data),
                                            "dd/MM/yyyy '`as' HH:mm"
                                          )}`}
                                        </Typography>
                                      ) : (
                                        <>
                                          <Typography
                                            fontSize={14}
                                            sx={{
                                              color: (theme) =>
                                                theme.palette.onPrimaryContainer
                                                  .main,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {`Data e hora: ${format(
                                              new Date(
                                                new Date(appointment.data)
                                                  .toISOString()
                                                  .slice(0, -1)
                                              ),
                                              "dd/MM/yyyy"
                                            )} a partir de ${
                                              appointment.agenda.cronograma[0].horarios[0].split(
                                                "-"
                                              )[0]
                                            } - Por ordem de chegada`}
                                          </Typography>

                                          <Typography
                                            fontSize={14}
                                            sx={{
                                              color: (theme) =>
                                                theme.palette.error.main,
                                              mt: 1,
                                            }}
                                          >
                                            Obs: Chegar 15 min antes do horário
                                            de início
                                          </Typography>
                                        </>
                                      )}
                                    </Stack>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item lg={10} md={10} xs={12} mt={2}>
                        <Typography
                          fontSize={14}
                          sx={{
                            color: (theme) =>
                              theme.palette.onPrimaryContainer.main,
                            fontWeight: 500,
                            mb: 2,
                          }}
                        >
                          Agora que{" "}
                          {singularizeWithArticle(
                            serviceGroup.nome.toLowerCase()
                          )}{" "}
                          já está marcada, você pode continuar agendando outras
                          consultas, exames e procedimentos.
                        </Typography>
                      </Grid>
                      <Grid item lg={10} md={10} xs={12} mt={2}>
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={12} xs={12}>
                            <Button
                              variant="outlined"
                              fullWidth
                              size="large"
                              onClick={() => goToDashboard()}
                            >
                              Ver meus agendamentos
                            </Button>
                          </Grid>
                          <Grid item lg={6} md={12} xs={12}>
                            <Button
                              variant="contained"
                              fullWidth
                              size="large"
                              onClick={() => goToAppointmentPage()}
                            >
                              Continuar agendando
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
