import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import { IServicePackage } from "../../types";
import {
  createUseServicePackageByIdKey,
  createUseServicePackageByPatientIdKey,
  createUseServicePackageKey,
} from "./key";

export const useServicePackage = (
  {
    clinicId,
  }: {
    clinicId: string;
  },
  options?: UseQueryOptions<IServicePackage[]>
) => {
  return useQuery(
    createUseServicePackageKey({
      clinicId,
    }),
    () =>
      api
        .get(`/users-patients/service-packages?idclinica=${clinicId}`)
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      refetchOnWindowFocus: false
    }
  );
};

export const useServicePackageById = (
  {
    servicePackageId,
  }: {
    servicePackageId: string;
  },
  options?: UseQueryOptions<IServicePackage>
) => {
  return useQuery(
    createUseServicePackageByIdKey({
      servicePackageId,
    }),
    () =>
      api
        .get(`users-patients/service-packages/${servicePackageId}`)
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      refetchOnWindowFocus: false
    }
  );
};

export const useServicePackageByPatientId = (
  {
    patientId,
  }: {
    patientId: string;
  },
  options?: UseQueryOptions<IServicePackage[]>
) => {
  return useQuery(
    createUseServicePackageByPatientIdKey({
      patientId,
    }),
    () =>
      api
        .get(`/users-patients/patient/${patientId}/service-packages`)
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      refetchOnWindowFocus: false
    }
  );
};
