import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import { IClinic } from "../../types";
import { createUseClinicKey } from "./keys";

export const useClinic = (options?: UseQueryOptions<IClinic[]>) => {
  return useQuery(
    createUseClinicKey(),
    () =>
      api.get(`/users-patients/clinics`).then((res) => res.data.data.clinics),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      refetchOnWindowFocus: false
    }
  );
};
