import {
  Button,
  DialogContentText,
  Grid,
  Stack,
  StepIcon,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
import { AuthModal } from "../../../../components/AuthModal";

import { ProfessionalScheduleCard } from "../../../../components/ProfessionalScheduleCard";
import { ProfessionalScheduleCardSkeleton } from "../../../../components/ProfessionalScheduleCardSkeleton";
import { useAppointment } from "../../../../hooks/useAppointment";
import {
  useAvailabilityMap,
  useAvailabilityTime,
} from "../../../../store/schedule";
import { useAuthenticatedUser } from "../../../../store/user";
import { singularizeWithArticle } from "../../../../utils/roles";
import { ScheduleDateList } from "./components/ScheduleDateList";

export const AppointmentStepSchedule = () => {
  const { data: user, isLoading } = useAuthenticatedUser();
  const {
    clinic,
    serviceGroup,
    serviceItem,
    selectedDate,
    dateSelected,
    professional,
    timeType,
    appointmentType,
    healthPlan,
    appointmentStep,
    promotionalItem,
    servicePackage,
    handleChangeAppointmentProfessionalClear,
  } = useAppointment();
  const navigate = useNavigate();
  const currentDate = new Date();
  const serviceItemsIds: string[] = [];

  if (serviceItem) {
    serviceItemsIds.push(serviceItem.iditemservico);
  }

  if (promotionalItem && servicePackage) {
    const promotionalItemAdditional =
      promotionalItem.itemsadicionais.length > 0
        ? servicePackage.itenspromocionais.filter(
            (item) =>
              item.itemservico.agendamento &&
              promotionalItem.itemsadicionais
                .map((addItem) => addItem.iditemservico)
                .includes(item.iditemservico)
          )
        : null;

    if (promotionalItemAdditional && promotionalItemAdditional.length > 0) {
      promotionalItemAdditional.forEach((item) => {
        serviceItemsIds.push(item.iditemservico);
      });
    }
  }

  const { data: availabilityMap, isLoading: isLoadingAvailabilityMap } =
    useAvailabilityMap(
      {
        clinicId: clinic ? clinic.idclinica : "",
        date: format(currentDate, "yyyy-MM-dd"),
        length: 30,
        serviceItemId: serviceItemsIds,
        healthPlanId:
          appointmentType === "convenio" && healthPlan
            ? healthPlan.idconvenio
            : undefined,
      },
      {
        enabled: !!clinic && !!serviceItem,
      }
    );

  const { data: availabilityTime, isLoading: isLoadingAvailabilityTime } =
    useAvailabilityTime(
      {
        clinicId: clinic ? clinic.idclinica : "",
        date: selectedDate ? format(selectedDate, "yyyy-MM-dd") : "",
        serviceItemId: serviceItemsIds,
        healthPlanId:
          appointmentType === "convenio" && healthPlan
            ? healthPlan.idconvenio
            : undefined,
      },
      {
        enabled:
          !!clinic &&
          !!serviceItem &&
          availabilityMap &&
          availabilityMap.length > 0 &&
          !isLoadingAvailabilityMap,
      }
    );

  const handleLogin = () => {
    navigate(
      `${professional?.nomeprofissional.replace(" ", "-").toLowerCase()}`,
      {
        state: {
          login: true,
        },
        preventScrollReset: true,
      }
    );
  };

  const handleRegister = () => {
    navigate(
      `${professional?.nomeprofissional.replace(" ", "-").toLowerCase()}`,
      {
        state: {
          register: true,
        },
        preventScrollReset: true,
      }
    );
  };

  const dataThatContainsAllServiceItems = availabilityTime
    ? availabilityTime.filter((professional) => {
        const professionalServiceIds = professional.itensservico.map(
          (item) => item.iditemservico
        );
        return serviceItemsIds.every((item) =>
          professionalServiceIds.includes(item)
        );
      })
    : null;

  useEffect(() => {
    ReactGA.event({
      category: "agendamento-web",
      action: "start",
      label: "agenda", //4
    });
  }, []);

  return (
    <Grid
      container
      mt={4}
      spacing={2}
      justifyContent="center"
      display="flex"
      component="section"
      id="agenda"
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          container
          columnSpacing={2}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Stack direction="row" spacing={1} mt={1} alignItems="center">
              <StepIcon icon="2" active={appointmentStep === 1} />
              <Typography
                component="h1"
                variant="h6"
                mb={2}
                fontWeight={600}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontSize: 18,
                }}
              >
                Escolha o melhor dia para{" "}
                {singularizeWithArticle(
                  serviceGroup ? serviceGroup.nome.toLowerCase() : ""
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item lg={12} md={12} xs={12} mt={2}>
            <ScheduleDateList
              availabilityMap={availabilityMap}
              isLoadingAvailabilityMap={isLoadingAvailabilityMap}
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container columnSpacing={2}>
              {!isLoadingAvailabilityMap &&
                availabilityMap &&
                availabilityMap.length > 0 &&
                selectedDate && (
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={4}>
                      <Typography
                        fontSize={20}
                        sx={{
                          color: (theme) =>
                            theme.palette.primary.main,
                          fontWeight: 700,
                        }}
                      >
                        Resultados para o{" "}
                        <b>{format(new Date(selectedDate), "dd 'de' MMMM")}</b>
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Grid container spacing={2}>
                        {isLoadingAvailabilityTime ? (
                          <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <ProfessionalScheduleCardSkeleton />
                            </Grid>
                          </>
                        ) : dataThatContainsAllServiceItems &&
                          dataThatContainsAllServiceItems.length > 0 ? (
                          <>
                            {dataThatContainsAllServiceItems.map(
                              (professional) =>
                                professional.schedules.map(
                                  (schedule) =>
                                    schedule.timetable.filter(
                                      (time) => time.available
                                    ).length > 0 && (
                                      <Grid
                                        key={schedule.agenda.idagenda}
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                      >
                                        <ProfessionalScheduleCard
                                          schedule={schedule}
                                        />
                                      </Grid>
                                    )
                                )
                            )}
                          </>
                        ) : (
                          <>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              mt={6}
                              mb={6}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                fontSize={18}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: 600,
                                  textAlign: "center",
                                }}
                              >
                                Não encontramos profissionais com agenda para a
                                especialidade selecionada
                              </Typography>

                              <Button
                                sx={{
                                  mt: 3,
                                }}
                                onClick={() =>
                                  window.scrollTo({
                                    behavior: "smooth",
                                    left: 0,
                                    top: 0,
                                  })
                                }
                              >
                                Escolher outra especialidade ou clínica
                              </Button>

                              <Typography
                                fontSize={14}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: 600,
                                  textAlign: "center",
                                  mt: 6,
                                }}
                              >
                                Prezado cliente caso não encontre a sua
                                especialidade médica ou exame entre em contato
                                pelo {process.env.REACT_APP_PHONE}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}

              {isLoadingAvailabilityMap && !availabilityMap && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ProfessionalScheduleCardSkeleton />
                </Grid>
              )}

              {!isLoadingAvailabilityMap &&
                availabilityMap &&
                availabilityMap.length === 0 && (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    mt={6}
                    mb={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      fontSize={18}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Não encontramos profissionais com agenda para a
                      especialidade selecionada
                    </Typography>

                    <Button
                      sx={{
                        mt: 3,
                      }}
                      onClick={() =>
                        window.scrollTo({
                          behavior: "smooth",
                          left: 0,
                          top: 0,
                        })
                      }
                    >
                      Escolher outra especialidade ou clínica
                    </Button>

                    <Typography
                      fontSize={14}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 600,
                        textAlign: "center",
                        mt: 6,
                      }}
                    >
                      Prezado cliente caso não encontre a sua especialidade
                      médica ou exame entre em contato pelo{" "}
                      {process.env.REACT_APP_PHONE}
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AuthModal
        open={!!professional && !user && !isLoading}
        title="Você está agendando uma consulta"
        onClickLogin={() => handleLogin()}
        onClickRegister={() => handleRegister()}
        onClose={() => handleChangeAppointmentProfessionalClear()}
      >
        <DialogContentText
          sx={{
            fontWeight: 600,
          }}
        >
          Informações:
        </DialogContentText>
        <ul>
          <li>
            <DialogContentText>
              Especialidade: {serviceItem?.nome}
            </DialogContentText>
          </li>
          <li>
            {timeType === "time" ? (
              <DialogContentText>
                {`Data e hora: ${format(
                  new Date(dateSelected.toISOString().slice(0, -1)),
                  "dd/MM/yyyy '`as' H:mm"
                )}`}
              </DialogContentText>
            ) : (
              <DialogContentText>
                {`Data e hora: ${format(
                  new Date(
                    new Date(
                      professional
                        ? professional.timetable[0].timeLabel.date
                        : new Date()
                    )
                      .toISOString()
                      .slice(0, -1)
                  ),
                  "dd/MM/yyyy 'a partir de' HH:mm"
                )} - Por ordem de chegada`}
              </DialogContentText>
            )}
          </li>
        </ul>
      </AuthModal>
    </Grid>
  );
};
