import {
  Box,
  Container,
  Grid,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import AppointmentAdsCard from "../../components/AppointmentAdsCard";
import { Footer } from "../../components/Footer";
import { useAppointment } from "../../hooks/useAppointment";
import { useAuthenticatedUser } from "../../store/user";
import { HomeNavbar } from "../Home/components/HomeNavbar";
import { AppointmentStepAccount } from "./components/AppointmentStepAccount";
import { AppointmentStepOne } from "./components/AppointmentStepOne";
import { AppointmentStepSchedule } from "./components/AppointmentStepSchedule";

export default function Appointment() {
  const theme = useTheme();
  const {
    clinic,
    serviceGroup,
    serviceItem,
    appointmentStep,
    professional,
    serviceItemHealtPlan,
  } = useAppointment();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  const { data: user } = useAuthenticatedUser();

  useEffect(() => {
    if (
      clinic &&
      serviceGroup &&
      (serviceItem || serviceItemHealtPlan) &&
      professional &&
      appointmentStep > 1 &&
      !!user
    ) {
      window.scrollTo({
        behavior: "smooth",
        top: document.body.scrollHeight,
      });
    }
  }, [
    clinic,
    serviceGroup,
    serviceItem,
    serviceItemHealtPlan,
    professional,
    appointmentStep,
    user,
  ]);

  return (
    <Box
      minHeight="100vh"
      id="scroll-container"
      position="relative"
      sx={{
        pb: 2,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <HomeNavbar dark={true} />
      <LinearProgress color="secondary" variant="determinate" value={12.5} />
      <Box
        minHeight="100vh"
        position="relative"
        sx={{
          pb: 4,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            minHeight: matchesSM ? "calc(100vh - 200px)" : "calc(90vh - 64px)",
          }}
        >
          <Grid container mt={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AppointmentAdsCard />
            </Grid>
          </Grid>

          {/* service group, clinic, service item */}
          <AppointmentStepOne />

          {/* profissional e agenda */}
          {clinic && serviceGroup && serviceItem && appointmentStep > 0 && (
            <>
              <Box
                sx={{
                  height: 2,
                  width: "100%",
                  mt: 8,
                  backgroundColor: (theme) => theme.palette.secondary.main,
                }}
              />
              <AppointmentStepSchedule />
            </>
          )}

          {/* account and confirm */}
          {clinic &&
            serviceGroup &&
            (serviceItem || serviceItemHealtPlan) &&
            professional &&
            appointmentStep > 1 &&
            !!user && (
              <>
                <Box
                  sx={{
                    height: 2,
                    width: "100%",
                    mt: 8,
                    backgroundColor: (theme) => theme.palette.secondary.main,
                  }}
                />
                <AppointmentStepAccount />
              </>
            )}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
