import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Loadable } from "../layout/Loadable";
import LoaderProgress from "../layout/LoaderProgress";
import { ProtectedRoute } from "../layout/ProtectedRoute";
import Appointment from "../pages/Appointment";
import Summary from "../pages/Appointment/Summary";
import AppointmentServicePackage from "../pages/AppointmentServicePackage";
import AppointmentServicePackageItem from "../pages/AppointmentServicePackage/components/AppointmentServicePackageItem";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import { useAppointmentWebSetup } from "../store/setup";
// import Home from "../pages/Home";
// const Login = Loadable(lazy(() => import("../pages/Auth/Login")));
// const Register = Loadable(lazy(() => import("../pages/Auth/Register")));
// const ConfirmEmail = Loadable(lazy(() => import("../pages/Auth/ConfirmEmail")));
// const Event = Loadable(lazy(() => import("../pages/Event")));
const Home = Loadable(lazy(() => import("../pages/Home")));
const DashboardSchedules = Loadable(
  lazy(() => import("../pages/Dashboard/Schedules"))
);
const DashboardAppointmentDetail = Loadable(
  lazy(() => import("../pages/Dashboard/AppointmentDetail"))
);
const UserAccount = Loadable(lazy(() => import("../pages/Dashboard/Account")));

export const AppRoutes = () => {
  const { isLoading } = useAppointmentWebSetup();

  if (isLoading) {
    return <LoaderProgress />;
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="agendamento" element={<Appointment />} />
      <Route path="agendamento-combo" element={<AppointmentServicePackage />} />
      <Route
        path="agendamento-combo/item-servico"
        element={
          <ProtectedRoute>
            <AppointmentServicePackageItem />
          </ProtectedRoute>
        }
      />
      <Route path="login" element={<Login />} />
      <Route path="cadastro" element={<Register />} />
      <Route path="recuperar-senha" element={<ForgotPassword />} />
      <Route
        path="agendamento/:appointmentId/summary"
        element={
          <ProtectedRoute>
            <Summary />
          </ProtectedRoute>
        }
      />
      <Route
        path="agendamento/:professional"
        element={
          <ProtectedRoute>
            <Summary />
          </ProtectedRoute>
        }
      />
      <Route
        path="agendamento-combo/:combo"
        element={
          <ProtectedRoute>
            <Summary />
          </ProtectedRoute>
        }
      />
      <Route
        path="paciente/dashboard"
        element={
          <ProtectedRoute>
            <DashboardSchedules />
          </ProtectedRoute>
        }
      />
      <Route
        path="paciente/dashboard/minha-conta"
        element={
          <ProtectedRoute>
            <UserAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path="paciente/dashboard/consulta/:appointmentId"
        element={
          <ProtectedRoute>
            <DashboardAppointmentDetail />
          </ProtectedRoute>
        }
      />
      {/* {
      path: "login",
      element: <Login />,
    },
    {
      path: "cadastro",
      element: <Register />,
    },
    {
      path: "agendamento/:professional", //:serviceOrderId/payment
      element: (
        <ProtectedRoute>
        <ConfirmSchedule />
        </ProtectedRoute>
        ),
      }, */}
    </Routes>
  );
  // return useRoutes([MainRoutes], config.basename);
};
