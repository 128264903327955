import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import { useAppointment } from "../../../../../../hooks/useAppointment";
import { singularizeWithArticle } from "../../../../../../utils/roles";

interface IScheduleCalendar {
  value: Date;
  minDate: Date;
  maxDate: Date;
  onChange: (value: any) => void;
  onClose: () => void;
  disableDates: (date: Date) => boolean;
  isOpen: boolean;
}

export const ScheduleCalendar = ({
  isOpen,
  value,
  onChange,
  onClose,
  disableDates,
  maxDate,
  minDate,
}: IScheduleCalendar) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  const { serviceGroup } = useAppointment();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Escolha o melhor dia para{" "}
        {singularizeWithArticle(
          serviceGroup ? serviceGroup.nome.toLowerCase() : ""
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          px: 2,
        }}
      >
        <DateCalendar
          sx={{
            width: matchesSM ? "100%" : 320,
            "& .MuiPickersDay-root": {
              backgroundColor: "#e6e6e6",
            },
            "& .Mui-selected": {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
            "& .Mui-disabled": {
              textDecoration: "line-through",
              backgroundColor: "transparent",
            },
          }}
          value={value}
          onChange={(newValue) => onChange(newValue)}
          shouldDisableDate={disableDates}
          maxDate={maxDate}
          minDate={minDate}
        />
      </DialogContent>
    </Dialog>
  );
};
