import { QueryKey } from "react-query";

export const createUseHealthPlansKey = (): QueryKey => ["useHealthPlansKey"];

export const createUseHealthPlansCategoryKey = (
  healthPlanId: string
): QueryKey => ["useHealthPlansCategoryKey", healthPlanId];

export const createUseHealthPlansCategoryItemsKey = (
  healthPlanId: string,
  clinicId: string,
  serviceGroupId: string,
  searchTerm: string
): QueryKey => [
  "useHealthPlansCategoryItemsKey",
  healthPlanId,
  clinicId,
  serviceGroupId,
  searchTerm,
];
