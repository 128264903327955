import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Divider,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  Step,
  StepContent,
  StepIcon,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { CaretDown, CaretUp, Check, X } from "phosphor-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppointment } from "../../../../hooks/useAppointment";
import { api } from "../../../../services/api";
import { useAppointments } from "../../../../store/appointment";
import { useServicePackageById } from "../../../../store/servicePackage";
import { useAuthenticatedUser } from "../../../../store/user";
import {
  APPOINTMENT_STATUS,
  AppointmentType,
  IPromotionalItem,
  IServiceItem,
} from "../../../../types";
import {
  formatClinicAddress,
  formatCurrency,
  formatName,
  formatPreparation,
} from "../../../../utils/masks";

interface IItemPreparation {
  preparo?: string;
  idgrupopreparo?: string;
  grupopreparo?: string;
  promotionalItems: IPromotionalItem[];
  agendamento: boolean;
  informacoes?: string;
}

export const AppointmentStepPromotionalItem = () => {
  const theme = useTheme();
  const matchesUpMd = useMediaQuery(theme.breakpoints.down("md"));
  let [searchParams] = useSearchParams();

  const {
    clinic,
    serviceGroup,
    handleChangeAppointmentServiceItem,
    handleChangeAppointmentStep,
    appointmentStep,
    handleChangeAppointmentServicePackage,
    handleChangePromotionalItem,
    servicePackage,
    account,
    dependent,
    handleReset,
  } = useAppointment();

  const { data: user } = useAuthenticatedUser();
  const navigate = useNavigate();

  const [isPromotionalItemsShow, setIsPromotionalItemsShow] = useState(
    !matchesUpMd
  );

  const id =
    account === 0 && user
      ? user.paciente.idpaciente
      : dependent
      ? dependent.idpaciente
      : "";

  const { data: appointments } = useAppointments(
    {
      patientId: id,
      clinicId: clinic ? clinic.idclinica : undefined,
    },
    {
      enabled: !!(account === 0
        ? user?.paciente.idpaciente
        : dependent?.idpaciente),
    }
  );

  useServicePackageById(
    {
      servicePackageId: servicePackage ? servicePackage.idcombo : "",
    },
    {
      enabled: !!servicePackage,
      onSuccess(data) {
        handleChangeAppointmentServicePackage(data);
      },
    }
  );

  const goToAppointmentNextStep = (item: IPromotionalItem) => {
    if (!serviceGroup) {
      return;
    }

    if (!clinic) {
      return;
    }

    handleChangeAppointmentServiceItem(item.itemservico);
    handleChangePromotionalItem(item);

    handleChangeAppointmentStep(1);

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 200);
  };

  const goToDashboard = () => {
    handleReset();
    navigate("/paciente/dashboard/", {
      replace: true,
    });
  };

  const goToHome = () => {
    handleReset();
    navigate("/", {
      replace: true,
    });
  };

  const comboItemsAppointmented: IServiceItem[] = [];
  const comboAppointmented: AppointmentType[] = [];
  const comboNotAppointmented: AppointmentType[] = [];

  if (appointments && servicePackage) {
    appointments.forEach((appointment) => {
      appointment.orderitems.forEach((item) => {
        if (item.idcombo && item.idcombo === servicePackage?.idcombo) {
          if (
            !(
              appointment.status === APPOINTMENT_STATUS.CANCELLED ||
              appointment.status === APPOINTMENT_STATUS.MISSED
            )
          ) {
            comboItemsAppointmented.push(item.itemservicos);
            comboAppointmented.push(appointment);
          } else {
            comboNotAppointmented.push(appointment);
          }
        }
      });
    });
  }

  const promotionalItemsServiceAppointmented =
    servicePackage?.itenspromocionais.filter((itemPromotional) => {
      return comboItemsAppointmented.find(
        (item) => item.iditemservico === itemPromotional.iditemservico
      );
    });

  const lastItemServiceAppointmented =
    promotionalItemsServiceAppointmented &&
    promotionalItemsServiceAppointmented.length > 0
      ? promotionalItemsServiceAppointmented[
          promotionalItemsServiceAppointmented.length - 1
        ]
      : null;

  const itemsEnabledToAppointment = servicePackage
    ? servicePackage.itenspromocionais.filter(
        (item) => item.itemservico.agendamento
      )
    : [];

  const lastItemToAppointmentIndex =
    lastItemServiceAppointmented && itemsEnabledToAppointment
      ? itemsEnabledToAppointment.findIndex(
          (item) =>
            item.iditempromocional ===
            lastItemServiceAppointmented?.iditempromocional
        )
      : -1;

  const nextItemToAppointment =
    servicePackage &&
    servicePackage.itenspromocionais.length > lastItemToAppointmentIndex &&
    itemsEnabledToAppointment.length > 0
      ? comboNotAppointmented.length === itemsEnabledToAppointment.length
        ? itemsEnabledToAppointment[lastItemToAppointmentIndex + 1]
        : comboNotAppointmented.length === 0
        ? itemsEnabledToAppointment[lastItemToAppointmentIndex + 1]
        : null
      : null;

  const promotionalItemAdditional =
    servicePackage &&
    nextItemToAppointment &&
    nextItemToAppointment.itemsadicionais &&
    nextItemToAppointment.itemsadicionais.length > 0
      ? servicePackage.itenspromocionais.filter(
          (item) =>
            item.itemservico.agendamento &&
            nextItemToAppointment.itemsadicionais
              .map((addItem) => addItem.iditemservico)
              .includes(item.iditemservico)
        )
      : null;

  let promotionalItems: IItemPreparation[] = [];
  if (servicePackage && servicePackage.itenspromocionais.length > 0) {
    for (const item of servicePackage.itenspromocionais) {
      if (item.preparo && item.grupopreparo) {
        const exists = promotionalItems.findIndex(
          (i) =>
            i.idgrupopreparo === item.grupopreparo &&
            i.agendamento === item.itemservico.agendamento
        );
        if (exists !== -1) {
          promotionalItems[exists].promotionalItems.push(item);
        } else {
          promotionalItems.push({
            promotionalItems: [item],
            grupopreparo: item.itemservico.categoriaservico.nome,
            preparo: item.preparo,
            idgrupopreparo: item.grupopreparo,
            agendamento: item.itemservico.agendamento,
            informacoes: item.informacoes,
          });
        }
      } else {
        promotionalItems.push({
          promotionalItems: [item],
          agendamento: item.itemservico.agendamento,
        });
      }
    }
  }

  const handleSendComboMail = async ({
    patientId,
    servicePackageId,
    clinicId,
  }: {
    servicePackageId: string;
    patientId: string;
    clinicId?: string;
  }) => {
    try {
      await api.post(
        `/users-patients/service-packages/${servicePackageId}/summary`,
        {
          patientId,
          servicePackageId,
          clinicId,
        }
      );
    } catch (error) {
      console.log("Erro ao enviar email");
    }
  };

  useEffect(() => {
    if (
      (!nextItemToAppointment ||
        (nextItemToAppointment && !nextItemToAppointment.agendamentoativo)) &&
      servicePackage &&
      servicePackage.itenspromocionais.length > 0 &&
      appointments
    ) {
      if (!searchParams.get("summary")) {
        handleSendComboMail({
          servicePackageId: servicePackage.idcombo,
          patientId: id,
          clinicId: clinic?.idclinica,
        });
      }
    }
  }, [
    nextItemToAppointment,
    servicePackage,
    appointments,
    searchParams,
    id,
    clinic,
  ]);

  return (
    <Grid
      container
      mt={2}
      justifyContent="center"
      display="flex"
      component="section"
      id="tipo"
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          component="form"
          display="flex"
          noValidate
          autoComplete="off"
        >
          {(!nextItemToAppointment ||
            (nextItemToAppointment &&
              !nextItemToAppointment.agendamentoativo)) &&
            servicePackage &&
            servicePackage.itenspromocionais.length > 0 &&
            appointments && (
              <>
                {searchParams.get("summary") ? (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="center"
                    display="flex"
                    flexDirection="column"
                    mt={2}
                    mb={4}
                  >
                    {searchParams.get("settled") ? (
                      <>
                        <Typography
                          fontSize={22}
                          sx={{
                            color: (theme) => theme.palette.secondary.main,
                            fontWeight: 600,
                            textAlign: "center",
                            mb: 2,
                          }}
                        >
                          {account === 0 && user
                            ? user.paciente.nomepaciente
                            : dependent
                            ? dependent.nomepaciente
                            : ""}
                          , identificamos que você já possui esse combo quitado
                          e que faltou
                        </Typography>
                        <Alert color="error">
                          <AlertTitle>Atenção</AlertTitle>
                          Para remarcar o agendamento, entre em contato com um
                          dos atendentes na central de remarcação pelo telefone{" "}
                          {process.env.REACT_APP_PHONE}
                        </Alert>
                      </>
                    ) : (
                      <>
                        <Typography
                          fontSize={22}
                          sx={{
                            color: (theme) => theme.palette.secondary.main,
                            fontWeight: 600,
                            textAlign: "center",
                            mb: 2,
                          }}
                        >
                          {account === 0 && user
                            ? user.paciente.nomepaciente
                            : dependent
                            ? dependent.nomepaciente
                            : ""}
                          , identificamos que você já possui esse combo agendado
                        </Typography>
                        <Alert color="error">
                          <AlertTitle>Atenção</AlertTitle>
                          Para remarcar uma consulta/exame ou cancelar o combo,
                          entre em contato com o administrador pelo telefone{" "}
                          {process.env.REACT_APP_PHONE}
                        </Alert>
                      </>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    justifyContent="center"
                    display="flex"
                  >
                    <Typography
                      fontSize={22}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        fontWeight: 600,
                        textAlign: "center",
                        mb: 2,
                      }}
                    >
                      {account === 0 && user
                        ? user.paciente.nomepaciente
                        : dependent
                        ? dependent.nomepaciente
                        : ""}
                      , seu combo foi agendado. Veja os detalhes abaixo.
                    </Typography>
                  </Grid>
                )}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        fontSize={18}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                          fontWeight: "bold",
                        }}
                      >
                        Combo: {formatName(servicePackage.titulo)}
                      </Typography>
                    </Grid>
                    {servicePackage.descricao && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography
                          fontSize={14}
                          sx={{
                            color: (theme) =>
                              theme.palette.onSurfaceVariant.main,
                            fontWeight: 400,
                          }}
                        >
                          {servicePackage.descricao}
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      display="flex"
                      alignItems="center"
                    >
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography
                          fontSize={24}
                          sx={{
                            color: (theme) => theme.palette.primary.main,
                            fontWeight: 600,
                          }}
                        >
                          {formatCurrency(Number(servicePackage.valor))}
                        </Typography>
                      </Stack>
                    </Grid>
                    {servicePackage.tipospagamentos && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Alert color="info">
                          {servicePackage.tipospagamentos}
                        </Alert>
                      </Grid>
                    )}
                  </Grid>

                  <Grid container spacing={3} mt={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12} mb={2}>
                      <Card variant="outlined">
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12} mb={2}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Box
                                  sx={{
                                    width: 8,
                                    height: 24,
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: 4,
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: 18,
                                    color:
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 600,
                                  }}
                                >
                                  Paciente e clínica
                                </Typography>
                              </Stack>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                fontSize={16}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: 600,
                                  mb: 1,
                                }}
                              >
                                Paciente:{" "}
                                {formatName(
                                  account === 0 && user
                                    ? user.paciente.nomepaciente
                                    : dependent
                                    ? dependent.nomepaciente
                                    : ""
                                )}
                              </Typography>
                              {clinic && (
                                <Stack mt={1}>
                                  <Typography
                                    fontSize={12}
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.onSurfaceVariant.main,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Clínica: {formatName(clinic.nome)}
                                  </Typography>
                                  <Typography
                                    fontSize={12}
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.onSurfaceVariant.main,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Endereço: {formatClinicAddress(clinic)}
                                  </Typography>
                                </Stack>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item lg={12} md={12} xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Box
                                  sx={{
                                    width: 8,
                                    height: 24,
                                    backgroundColor: theme.palette.primary.main,
                                    borderRadius: 4,
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: 18,
                                    color:
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 600,
                                  }}
                                >
                                  Detalhes dos agendamentos dos itens
                                </Typography>
                              </Stack>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                fontSize={16}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: 500,
                                }}
                              >
                                Itens do combo:
                              </Typography>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Grid
                                container
                                spacing={2}
                                sx={{
                                  pl: 4,
                                }}
                              >
                                <Stepper orientation="vertical">
                                  {promotionalItems.map(
                                    (promotionalItem, index) =>
                                      promotionalItem.preparo ? (
                                        <Step active>
                                          <StepLabel>
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              spacing={2}
                                            >
                                              <Typography
                                                fontSize={14}
                                                sx={{
                                                  color: (theme) =>
                                                    theme.palette
                                                      .onSurfaceVariant.main,
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {promotionalItem.grupopreparo}
                                              </Typography>

                                              {!promotionalItem.agendamento && (
                                                <Chip
                                                  label="Não precisa de agendamento"
                                                  color="warning"
                                                  size="small"
                                                />
                                              )}
                                            </Stack>
                                          </StepLabel>
                                          <StepContent>
                                            <Grid container spacing={2}>
                                              <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Card
                                                  sx={{
                                                    backgroundColor:
                                                      "rgba(221,227,234,0.1)",
                                                    height: "100%",
                                                  }}
                                                >
                                                  <CardContent>
                                                    <Grid container spacing={2}>
                                                      {promotionalItem.promotionalItems.map(
                                                        (item, index) => (
                                                          <Grid
                                                            item
                                                            key={
                                                              item.iditempromocional
                                                            }
                                                          >
                                                            <Stack
                                                              spacing={1}
                                                              direction="row"
                                                              alignItems="center"
                                                            >
                                                              {comboItemsAppointmented.find(
                                                                (comboItem) =>
                                                                  comboItem.iditemservico ===
                                                                  item.iditemservico
                                                              ) && (
                                                                <Check
                                                                  size={14}
                                                                  min={14}
                                                                  color={
                                                                    theme
                                                                      .palette
                                                                      .primary
                                                                      .main
                                                                  }
                                                                />
                                                              )}
                                                              <Typography
                                                                fontSize={14}
                                                                sx={{
                                                                  color: (
                                                                    theme
                                                                  ) =>
                                                                    theme
                                                                      .palette
                                                                      .onSurfaceVariant
                                                                      .main,
                                                                  fontWeight: 500,
                                                                }}
                                                              >
                                                                {formatName(
                                                                  item
                                                                    .itemservico
                                                                    .nome
                                                                )}
                                                                {index <
                                                                  promotionalItem
                                                                    .promotionalItems
                                                                    .length &&
                                                                  `,`}
                                                              </Typography>
                                                            </Stack>
                                                          </Grid>
                                                        )
                                                      )}

                                                      <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                        mt={2}
                                                      >
                                                        <Typography
                                                          fontSize={14}
                                                          sx={{
                                                            color: (theme) =>
                                                              theme.palette
                                                                .onPrimaryContainer
                                                                .main,
                                                            fontWeight: 500,
                                                            mb: 1,
                                                          }}
                                                        >
                                                          Preparo para{" "}
                                                          {
                                                            promotionalItem.grupopreparo
                                                          }
                                                        </Typography>
                                                        <Alert
                                                          color="warning"
                                                          icon={false}
                                                        >
                                                          {formatPreparation(
                                                            promotionalItem.preparo
                                                          )}
                                                        </Alert>
                                                      </Grid>

                                                      {promotionalItem.informacoes && (
                                                        <Grid
                                                          item
                                                          lg={12}
                                                          md={12}
                                                          sm={12}
                                                          xs={12}
                                                          mt={2}
                                                        >
                                                          <Typography
                                                            fontSize={14}
                                                            sx={{
                                                              color: (theme) =>
                                                                theme.palette
                                                                  .onPrimaryContainer
                                                                  .main,
                                                              fontWeight: 500,
                                                              mb: 1,
                                                            }}
                                                          >
                                                            Informações ao
                                                            paciente
                                                          </Typography>
                                                          <Alert
                                                            color="warning"
                                                            icon={false}
                                                          >
                                                            {formatPreparation(
                                                              promotionalItem.informacoes
                                                            )}
                                                          </Alert>
                                                        </Grid>
                                                      )}

                                                      {clinic &&
                                                        !promotionalItem.agendamento && (
                                                          <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                            mt={2}
                                                          >
                                                            <Typography
                                                              fontSize={14}
                                                              sx={{
                                                                color: (
                                                                  theme
                                                                ) =>
                                                                  theme.palette
                                                                    .onPrimaryContainer
                                                                    .main,
                                                                fontWeight: 500,
                                                                mb: 1,
                                                              }}
                                                            >
                                                              Local para a
                                                              coleta
                                                            </Typography>
                                                            <Typography
                                                              fontSize={14}
                                                              sx={{
                                                                color: (
                                                                  theme
                                                                ) =>
                                                                  theme.palette
                                                                    .onSurfaceVariant
                                                                    .main,
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              Clínica:{" "}
                                                              {formatName(
                                                                clinic.nome
                                                              )}
                                                            </Typography>
                                                            <Typography
                                                              fontSize={14}
                                                              sx={{
                                                                color: (
                                                                  theme
                                                                ) =>
                                                                  theme.palette
                                                                    .onSurfaceVariant
                                                                    .main,
                                                                fontWeight: 500,
                                                              }}
                                                            >
                                                              Endereço:{" "}
                                                              {formatClinicAddress(
                                                                clinic
                                                              )}
                                                            </Typography>
                                                          </Grid>
                                                        )}
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          </StepContent>
                                        </Step>
                                      ) : (
                                        promotionalItem.promotionalItems.map(
                                          (item) => (
                                            <Step
                                              active
                                              completed={
                                                !!comboItemsAppointmented.find(
                                                  (comboItem) =>
                                                    comboItem.iditemservico ===
                                                    item.iditemservico
                                                )
                                              }
                                            >
                                              <StepLabel>
                                                <Stack
                                                  direction="row"
                                                  alignItems="center"
                                                  spacing={2}
                                                >
                                                  <Typography
                                                    fontSize={16}
                                                    sx={{
                                                      color: (theme) =>
                                                        theme.palette
                                                          .onSurfaceVariant
                                                          .main,
                                                      fontWeight: 500,
                                                    }}
                                                  >
                                                    {formatName(
                                                      item.itemservico.nome
                                                    )}
                                                  </Typography>
                                                  {!item.itemservico
                                                    .agendamento && (
                                                    <Chip
                                                      label="Não precisa de agendamento"
                                                      color="warning"
                                                      size="small"
                                                    />
                                                  )}
                                                  {!item.agendamentoativo && (
                                                    <Chip
                                                      label="Siga as instruções para agendar"
                                                      color="warning"
                                                      size="small"
                                                    />
                                                  )}
                                                  {comboItemsAppointmented.find(
                                                    (comboItem) =>
                                                      comboItem.iditemservico ===
                                                      item.iditemservico
                                                  ) && (
                                                    <Chip
                                                      label="Agendado"
                                                      color="primary"
                                                      size="small"
                                                    />
                                                  )}
                                                </Stack>
                                              </StepLabel>
                                              <StepContent>
                                                <Grid container spacing={2}>
                                                  <Grid
                                                    key={item.iditempromocional}
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                  >
                                                    <Card
                                                      sx={{
                                                        backgroundColor: `rgba(214, 227, 255, 0.1)`,
                                                        height: "100%",
                                                      }}
                                                    >
                                                      <CardContent>
                                                        <Grid
                                                          container
                                                          spacing={2}
                                                        >
                                                          {appointments.find(
                                                            (appointment) =>
                                                              appointment.orderitems.find(
                                                                (orderItem) =>
                                                                  orderItem.iditemcombo ===
                                                                  item.iditempromocional
                                                              )
                                                          ) && (
                                                            <>
                                                              <Grid
                                                                item
                                                                lg={6}
                                                                md={6}
                                                                sm={12}
                                                                xs={12}
                                                              >
                                                                <Grid
                                                                  container
                                                                  spacing={2}
                                                                >
                                                                  <Grid
                                                                    item
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    xs={12}
                                                                  >
                                                                    <Typography
                                                                      fontSize={
                                                                        14
                                                                      }
                                                                      sx={{
                                                                        color: (
                                                                          theme
                                                                        ) =>
                                                                          theme
                                                                            .palette
                                                                            .onSurfaceVariant
                                                                            .main,
                                                                        fontWeight: 500,
                                                                      }}
                                                                    >
                                                                      <b>
                                                                        Profissional:
                                                                      </b>{" "}
                                                                      {formatName(
                                                                        appointments.find(
                                                                          (
                                                                            appointment
                                                                          ) =>
                                                                            appointment.orderitems.find(
                                                                              (
                                                                                orderItem
                                                                              ) =>
                                                                                orderItem.iditemcombo ===
                                                                                item.iditempromocional
                                                                            )
                                                                        )
                                                                          ?.profissional
                                                                          .nomecomercial
                                                                          ? appointments.find(
                                                                              (
                                                                                appointment
                                                                              ) =>
                                                                                appointment.orderitems.find(
                                                                                  (
                                                                                    orderItem
                                                                                  ) =>
                                                                                    orderItem.iditemcombo ===
                                                                                    item.iditempromocional
                                                                                )
                                                                            )!
                                                                              .profissional
                                                                              .nomecomercial
                                                                          : appointments.find(
                                                                              (
                                                                                appointment
                                                                              ) =>
                                                                                appointment.orderitems.find(
                                                                                  (
                                                                                    orderItem
                                                                                  ) =>
                                                                                    orderItem.iditemcombo ===
                                                                                    item.iditempromocional
                                                                                )
                                                                            )
                                                                              ?.profissional
                                                                              .nome
                                                                          ? appointments.find(
                                                                              (
                                                                                appointment
                                                                              ) =>
                                                                                appointment.orderitems.find(
                                                                                  (
                                                                                    orderItem
                                                                                  ) =>
                                                                                    orderItem.iditemcombo ===
                                                                                    item.iditempromocional
                                                                                )
                                                                            )!
                                                                              .profissional
                                                                              .nome
                                                                          : ""
                                                                      )}
                                                                    </Typography>
                                                                    <Stack>
                                                                      {!appointments.find(
                                                                        (
                                                                          appointment
                                                                        ) =>
                                                                          appointment.orderitems.find(
                                                                            (
                                                                              orderItem
                                                                            ) =>
                                                                              orderItem.iditemcombo ===
                                                                              item.iditempromocional
                                                                          )
                                                                      )?.agenda
                                                                        .type_vacancy ? (
                                                                        <Typography
                                                                          fontSize={
                                                                            14
                                                                          }
                                                                          sx={{
                                                                            color:
                                                                              (
                                                                                theme
                                                                              ) =>
                                                                                theme
                                                                                  .palette
                                                                                  .onSurfaceVariant
                                                                                  .main,
                                                                            fontWeight: 500,
                                                                          }}
                                                                        >
                                                                          <b>
                                                                            Data
                                                                            e
                                                                            hora:{" "}
                                                                          </b>
                                                                          {`${format(
                                                                            new Date(
                                                                              appointments.find(
                                                                                (
                                                                                  appointment
                                                                                ) =>
                                                                                  appointment.orderitems.find(
                                                                                    (
                                                                                      orderItem
                                                                                    ) =>
                                                                                      orderItem.iditemcombo ===
                                                                                      item.iditempromocional
                                                                                  )
                                                                              )!.data
                                                                            ),
                                                                            "dd/MM/yyyy '`as' HH:mm"
                                                                          )}`}
                                                                        </Typography>
                                                                      ) : (
                                                                        <>
                                                                          <Typography
                                                                            fontSize={
                                                                              14
                                                                            }
                                                                            sx={{
                                                                              color:
                                                                                (
                                                                                  theme
                                                                                ) =>
                                                                                  theme
                                                                                    .palette
                                                                                    .onSurfaceVariant
                                                                                    .main,
                                                                              fontWeight: 500,
                                                                            }}
                                                                          >
                                                                            <b>
                                                                              Data
                                                                              e
                                                                              hora:{" "}
                                                                            </b>
                                                                            {`${format(
                                                                              new Date(
                                                                                new Date(
                                                                                  appointments.find(
                                                                                    (
                                                                                      appointment
                                                                                    ) =>
                                                                                      appointment.orderitems.find(
                                                                                        (
                                                                                          orderItem
                                                                                        ) =>
                                                                                          orderItem.iditemcombo ===
                                                                                          item.iditempromocional
                                                                                      )
                                                                                  )!.data
                                                                                )
                                                                                  .toISOString()
                                                                                  .slice(
                                                                                    0,
                                                                                    -1
                                                                                  )
                                                                              ),
                                                                              "dd/MM/yyyy"
                                                                            )} a partir de ${
                                                                              appointments
                                                                                .find(
                                                                                  (
                                                                                    appointment
                                                                                  ) =>
                                                                                    appointment.orderitems.find(
                                                                                      (
                                                                                        orderItem
                                                                                      ) =>
                                                                                        orderItem.iditemcombo ===
                                                                                        item.iditempromocional
                                                                                    )
                                                                                )
                                                                                ?.agenda.cronograma[0].horarios[0].split(
                                                                                  "-"
                                                                                )[0]
                                                                            } - Por ordem de chegada`}
                                                                          </Typography>

                                                                          <Typography
                                                                            fontSize={
                                                                              14
                                                                            }
                                                                            sx={{
                                                                              color:
                                                                                (
                                                                                  theme
                                                                                ) =>
                                                                                  theme
                                                                                    .palette
                                                                                    .secondary
                                                                                    .main,
                                                                              mt: 1,
                                                                            }}
                                                                          >
                                                                            Obs:
                                                                            Chegar
                                                                            15
                                                                            min
                                                                            antes
                                                                            do
                                                                            horário
                                                                            de
                                                                            início
                                                                          </Typography>
                                                                        </>
                                                                      )}
                                                                    </Stack>
                                                                  </Grid>
                                                                </Grid>
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                lg={6}
                                                                md={6}
                                                                sm={12}
                                                                xs={12}
                                                              >
                                                                <Typography
                                                                  fontSize={14}
                                                                  sx={{
                                                                    color: (
                                                                      theme
                                                                    ) =>
                                                                      theme
                                                                        .palette
                                                                        .onSurfaceVariant
                                                                        .main,
                                                                    fontWeight: 500,
                                                                  }}
                                                                >
                                                                  <b>
                                                                    Paciente:
                                                                  </b>{" "}
                                                                  {formatName(
                                                                    appointments.find(
                                                                      (
                                                                        appointment
                                                                      ) =>
                                                                        appointment.orderitems.find(
                                                                          (
                                                                            orderItem
                                                                          ) =>
                                                                            orderItem.iditemcombo ===
                                                                            item.iditempromocional
                                                                        )
                                                                    )?.paciente
                                                                      .nomepaciente
                                                                      ? appointments.find(
                                                                          (
                                                                            appointment
                                                                          ) =>
                                                                            appointment.orderitems.find(
                                                                              (
                                                                                orderItem
                                                                              ) =>
                                                                                orderItem.iditemcombo ===
                                                                                item.iditempromocional
                                                                            )
                                                                        )!
                                                                          .paciente
                                                                          .nomepaciente
                                                                      : ""
                                                                  )}
                                                                </Typography>
                                                                <Typography
                                                                  fontSize={14}
                                                                  sx={{
                                                                    color: (
                                                                      theme
                                                                    ) =>
                                                                      theme
                                                                        .palette
                                                                        .onSurfaceVariant
                                                                        .main,
                                                                    fontWeight: 500,
                                                                  }}
                                                                >
                                                                  <b>
                                                                    Clínica:
                                                                  </b>{" "}
                                                                  {formatName(
                                                                    appointments.find(
                                                                      (
                                                                        appointment
                                                                      ) =>
                                                                        appointment.orderitems.find(
                                                                          (
                                                                            orderItem
                                                                          ) =>
                                                                            orderItem.iditemcombo ===
                                                                            item.iditempromocional
                                                                        )
                                                                    )?.agenda
                                                                      .clinica
                                                                      ? appointments.find(
                                                                          (
                                                                            appointment
                                                                          ) =>
                                                                            appointment.orderitems.find(
                                                                              (
                                                                                orderItem
                                                                              ) =>
                                                                                orderItem.iditemcombo ===
                                                                                item.iditempromocional
                                                                            )
                                                                        )!
                                                                          .agenda
                                                                          .clinica
                                                                          .nome
                                                                      : ""
                                                                  )}
                                                                </Typography>
                                                                <Typography
                                                                  fontSize={14}
                                                                  sx={{
                                                                    color: (
                                                                      theme
                                                                    ) =>
                                                                      theme
                                                                        .palette
                                                                        .onSurfaceVariant
                                                                        .main,
                                                                    fontWeight: 500,
                                                                  }}
                                                                >
                                                                  <b>
                                                                    Endereço:
                                                                  </b>{" "}
                                                                  {appointments.find(
                                                                    (
                                                                      appointment
                                                                    ) =>
                                                                      appointment.orderitems.find(
                                                                        (
                                                                          orderItem
                                                                        ) =>
                                                                          orderItem.iditemcombo ===
                                                                          item.iditempromocional
                                                                      )
                                                                  )?.agenda
                                                                    .clinica
                                                                    ? formatClinicAddress(
                                                                        appointments.find(
                                                                          (
                                                                            appointment
                                                                          ) =>
                                                                            appointment.orderitems.find(
                                                                              (
                                                                                orderItem
                                                                              ) =>
                                                                                orderItem.iditemcombo ===
                                                                                item.iditempromocional
                                                                            )
                                                                        )!
                                                                          .agenda
                                                                          .clinica
                                                                      )
                                                                    : ""}
                                                                </Typography>
                                                              </Grid>
                                                            </>
                                                          )}

                                                          {nextItemToAppointment &&
                                                            nextItemToAppointment.iditempromocional ===
                                                              item.iditempromocional &&
                                                            nextItemToAppointment.informacoes && (
                                                              <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                                mt={2}
                                                              >
                                                                <Alert color="warning">
                                                                  <AlertTitle>
                                                                    Instruções
                                                                  </AlertTitle>
                                                                  {formatPreparation(
                                                                    nextItemToAppointment.informacoes
                                                                  )}
                                                                </Alert>
                                                              </Grid>
                                                            )}

                                                          {item.preparo ? (
                                                            <>
                                                              <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                              >
                                                                <Divider
                                                                  sx={{
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                />
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                              >
                                                                <Typography
                                                                  fontSize={14}
                                                                  sx={{
                                                                    color: (
                                                                      theme
                                                                    ) =>
                                                                      theme
                                                                        .palette
                                                                        .onSurfaceVariant
                                                                        .main,
                                                                    fontWeight: 500,
                                                                  }}
                                                                >
                                                                  Preparo:
                                                                </Typography>
                                                                <Alert
                                                                  color="warning"
                                                                  icon={false}
                                                                >
                                                                  {formatPreparation(
                                                                    item.preparo
                                                                  )}
                                                                </Alert>
                                                              </Grid>
                                                            </>
                                                          ) : (
                                                            item.itemservico
                                                              .preparo && (
                                                              <>
                                                                <Grid
                                                                  item
                                                                  lg={12}
                                                                  md={12}
                                                                  sm={12}
                                                                  xs={12}
                                                                >
                                                                  <Divider
                                                                    sx={{
                                                                      width:
                                                                        "100%",
                                                                    }}
                                                                  />
                                                                </Grid>
                                                                <Grid
                                                                  item
                                                                  lg={12}
                                                                  md={12}
                                                                  sm={12}
                                                                  xs={12}
                                                                >
                                                                  <Typography
                                                                    fontSize={
                                                                      14
                                                                    }
                                                                    sx={{
                                                                      color: (
                                                                        theme
                                                                      ) =>
                                                                        theme
                                                                          .palette
                                                                          .onSurfaceVariant
                                                                          .main,
                                                                      fontWeight: 500,
                                                                    }}
                                                                  >
                                                                    Preparo:
                                                                  </Typography>
                                                                  <Alert
                                                                    color="warning"
                                                                    icon={false}
                                                                  >
                                                                    {formatPreparation(
                                                                      item
                                                                        .itemservico
                                                                        .preparo
                                                                    )}
                                                                  </Alert>
                                                                </Grid>
                                                              </>
                                                            )
                                                          )}
                                                        </Grid>
                                                      </CardContent>
                                                    </Card>
                                                  </Grid>
                                                </Grid>
                                              </StepContent>
                                            </Step>
                                          )
                                        )
                                      )
                                  )}
                                </Stepper>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    {clinic && clinic.info_paciente && (
                      <Grid item lg={12} md={12} sm={12} xs={12} mb={2}>
                        <Card variant="outlined">
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item lg={12} md={12} sm={12} xs={12} mb={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Box
                                    sx={{
                                      width: 8,
                                      height: 24,
                                      backgroundColor:
                                        theme.palette.primary.main,
                                      borderRadius: 4,
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: 18,
                                      color:
                                        theme.palette.onPrimaryContainer.main,
                                      fontWeight: 600,
                                    }}
                                  >
                                    Informações ao paciente
                                  </Typography>
                                </Stack>
                              </Grid>

                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Card
                                  sx={{
                                    backgroundColor:
                                      theme.palette.surfaceVariant.main,
                                    height: "100%",
                                  }}
                                >
                                  <CardContent>
                                    <Grid container>
                                      <Grid item>
                                        <Typography
                                          fontSize={16}
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.onPrimaryContainer
                                                .main,
                                            fontWeight: 600,
                                          }}
                                        >
                                          {clinic.info_paciente}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    <Grid item lg={12} md={12} xs={12} mt={2}>
                      <Typography
                        fontSize={16}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                          fontWeight: 500,
                          mb: 2,
                        }}
                      >
                        Agora que seu combo já está agendado, você pode
                        continuar agendando outros combos, consultas, exames e
                        procedimentos.
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                      <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => goToDashboard()}
                      >
                        Ver meus agendamentos
                      </Button>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        onClick={() => goToHome()}
                      >
                        Continuar agendando
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

          {nextItemToAppointment && nextItemToAppointment.agendamentoativo && (
            <>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="center"
                display="flex"
              >
                <Stack direction="row" spacing={1} mt={1} alignItems="center">
                  <StepIcon icon="1" active={appointmentStep === 0} />
                  <Typography
                    component="h1"
                    variant="h6"
                    mb={2}
                    fontWeight={600}
                    sx={{
                      color: (theme) => theme.palette.text.primary,
                      fontSize: 18,
                    }}
                  >
                    Agendamento de combo
                  </Typography>
                </Stack>
              </Grid>

              {serviceGroup && servicePackage && clinic && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xs={12}>
                      <Card
                        sx={{
                          backgroundColor: "rgba(221,227,234,0.1)",
                          height: "100%",
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                fontSize={18}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: "bold",
                                }}
                              >
                                Combo: ({formatName(servicePackage.titulo)})
                              </Typography>
                            </Grid>

                            {servicePackage.descricao && (
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                  fontSize={14}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onSurfaceVariant.main,
                                    fontWeight: 400,
                                  }}
                                >
                                  {servicePackage.descricao}
                                </Typography>
                              </Grid>
                            )}

                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              display="flex"
                              alignItems="center"
                            >
                              <Typography
                                fontSize={24}
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                  fontWeight: 600,
                                }}
                              >
                                {formatCurrency(Number(servicePackage.valor))}
                              </Typography>
                            </Grid>

                            {servicePackage.tipospagamentos && (
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Alert color="info">
                                  {servicePackage.tipospagamentos}
                                </Alert>
                              </Grid>
                            )}
                          </Grid>

                          <Grid container mt={3} spacing={1}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Typography
                                  fontSize={16}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Tipo de agendamento:
                                </Typography>

                                <Chip label="Particular" color="primary" />
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item lg={12} md={12} xs={12} mt={2}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          display="flex"
                          alignItems="center"
                        >
                          <Typography
                            fontSize={16}
                            sx={{
                              color: (theme) =>
                                theme.palette.onPrimaryContainer.main,
                              fontWeight: 500,
                            }}
                          >
                            Itens do combo:
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={6}
                          display="flex"
                          justifyContent="end"
                          alignItems="center"
                        >
                          <Button
                            endIcon={
                              isPromotionalItemsShow ? (
                                <CaretUp />
                              ) : (
                                <CaretDown />
                              )
                            }
                            size="small"
                            onClick={() =>
                              setIsPromotionalItemsShow((oldValue) => !oldValue)
                            }
                          >
                            Ver mais detalhes
                          </Button>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                          <Collapse in={isPromotionalItemsShow}>
                            <Stepper orientation="vertical">
                              {promotionalItems.map((promotionalItem, index) =>
                                promotionalItem.preparo ? (
                                  <Step
                                    active={
                                      !promotionalItem.agendamento ||
                                      promotionalItem.promotionalItems.includes(
                                        nextItemToAppointment
                                      )
                                    }
                                    expanded={true}
                                  >
                                    <StepLabel>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Typography
                                          fontSize={14}
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.onSurfaceVariant
                                                .main,
                                            fontWeight: 600,
                                          }}
                                        >
                                          {promotionalItem.grupopreparo}
                                        </Typography>

                                        {!promotionalItem.agendamento && (
                                          <Chip
                                            label="Não precisa de agendamento"
                                            color="warning"
                                            size="small"
                                          />
                                        )}
                                      </Stack>
                                    </StepLabel>
                                    <StepContent>
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                        >
                                          <Card
                                            variant="outlined"
                                            sx={{
                                              backgroundColor:
                                                !promotionalItem.agendamento ||
                                                promotionalItem.promotionalItems.includes(
                                                  nextItemToAppointment
                                                )
                                                  ? "rgba(0, 161, 169, 0.01)"
                                                  : "rgba(221,227,234,0.1)",
                                              height: "100%",
                                              borderColor:
                                                !promotionalItem.agendamento ||
                                                promotionalItem.promotionalItems.includes(
                                                  nextItemToAppointment
                                                )
                                                  ? theme.palette.primary.main
                                                  : theme.palette.neutral.main,
                                              transition:
                                                theme.transitions.create(
                                                  ["border"],
                                                  {
                                                    duration:
                                                      theme.transitions.duration
                                                        .standard,
                                                  }
                                                ),
                                            }}
                                          >
                                            <CardContent>
                                              <Grid container spacing={2}>
                                                {/* <Grid
                                                      item
                                                      lg={12}
                                                      md={12}
                                                      sm={12}
                                                      xs={12}
                                                    >
                                                      <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                      >
                                                        <StepIcon
                                                          icon={index + 1}
                                                          active={
                                                            !promotionalItem.agendamento ||
                                                            promotionalItem.promotionalItems.includes(
                                                              nextItemToAppointment
                                                            )
                                                          }
                                                        />
                                                        <Typography
                                                          fontSize={14}
                                                          sx={{
                                                            color: (theme) =>
                                                              theme.palette
                                                                .onSurfaceVariant
                                                                .main,
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          {
                                                            promotionalItem.grupopreparo
                                                          }
                                                        </Typography>

                                                        {!promotionalItem.agendamento && (
                                                          <Chip
                                                            label="Não precisa de agendamento"
                                                            color="warning"
                                                            size="small"
                                                          />
                                                        )}
                                                      </Stack>
                                                    </Grid> */}

                                                {promotionalItem.promotionalItems.map(
                                                  (item, index) => (
                                                    <Grid
                                                      item
                                                      key={
                                                        item.iditempromocional
                                                      }
                                                    >
                                                      <Stack
                                                        spacing={1}
                                                        direction="row"
                                                        alignItems="center"
                                                      >
                                                        {comboItemsAppointmented.find(
                                                          (comboItem) =>
                                                            comboItem.iditemservico ===
                                                            item.iditemservico
                                                        ) && (
                                                          <Check
                                                            size={14}
                                                            min={14}
                                                            color={
                                                              theme.palette
                                                                .primary.main
                                                            }
                                                          />
                                                        )}
                                                        <Typography
                                                          fontSize={14}
                                                          sx={{
                                                            color: (theme) =>
                                                              theme.palette
                                                                .onSurfaceVariant
                                                                .main,
                                                            fontWeight: 400,
                                                          }}
                                                        >
                                                          {formatName(
                                                            item.itemservico
                                                              .nome
                                                          )}
                                                          {index <
                                                            promotionalItem
                                                              .promotionalItems
                                                              .length && `,`}
                                                        </Typography>
                                                      </Stack>
                                                    </Grid>
                                                  )
                                                )}

                                                <Grid
                                                  item
                                                  lg={12}
                                                  md={12}
                                                  sm={12}
                                                  xs={12}
                                                  mt={2}
                                                >
                                                  <Typography
                                                    fontSize={14}
                                                    sx={{
                                                      color: (theme) =>
                                                        theme.palette
                                                          .onPrimaryContainer
                                                          .main,
                                                      fontWeight: 500,
                                                      mb: 1,
                                                    }}
                                                  >
                                                    Preparo para{" "}
                                                    {
                                                      promotionalItem.grupopreparo
                                                    }
                                                  </Typography>
                                                  <Alert
                                                    color="warning"
                                                    icon={false}
                                                  >
                                                    {formatPreparation(
                                                      promotionalItem.preparo
                                                    )}
                                                  </Alert>
                                                </Grid>

                                                {promotionalItem.informacoes && (
                                                  <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    mt={2}
                                                  >
                                                    <Alert color="warning">
                                                      <AlertTitle>
                                                        Informações
                                                      </AlertTitle>
                                                      {
                                                        promotionalItem.informacoes
                                                      }
                                                    </Alert>
                                                  </Grid>
                                                )}
                                              </Grid>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      </Grid>
                                    </StepContent>
                                  </Step>
                                ) : (
                                  promotionalItem.promotionalItems.map(
                                    (item) => (
                                      <Step
                                        active={
                                          !item.agendamentoativo ? true : false
                                        }
                                        completed={
                                          !!comboItemsAppointmented.find(
                                            (comboItem) =>
                                              comboItem.iditemservico ===
                                              item.iditemservico
                                          )
                                        }
                                        expanded
                                      >
                                        <StepLabel>
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={2}
                                          >
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              spacing={1}
                                            >
                                              {!comboItemsAppointmented.find(
                                                (comboItem) =>
                                                  comboItem.iditemservico ===
                                                  item.iditemservico
                                              ) && <X size={14} />}
                                              <Typography
                                                fontSize={14}
                                                sx={{
                                                  color: (theme) =>
                                                    theme.palette
                                                      .onSurfaceVariant.main,
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {formatName(
                                                  item.itemservico.nome
                                                )}
                                              </Typography>
                                            </Stack>
                                            {!item.agendamentoativo && (
                                              <Chip
                                                label="Siga as instruções para agendar"
                                                color="warning"
                                                size="small"
                                              />
                                            )}

                                            {!item.itemservico.agendamento && (
                                              <Chip
                                                label="Não precisa de agendamento"
                                                color="warning"
                                                size="small"
                                              />
                                            )}

                                            {comboItemsAppointmented.find(
                                              (comboItem) =>
                                                comboItem.iditemservico ===
                                                item.iditemservico
                                            ) && (
                                              <Chip
                                                label="Agendado"
                                                color="primary"
                                                size="small"
                                              />
                                            )}
                                          </Stack>
                                        </StepLabel>
                                        <StepContent>
                                          {(item.itemservico.preparo ||
                                            item.informacoes) && (
                                            <Grid container spacing={2}>
                                              <Grid
                                                key={item.iditempromocional}
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Card
                                                  variant="outlined"
                                                  sx={{
                                                    backgroundColor:
                                                      comboItemsAppointmented.find(
                                                        (comboItem) =>
                                                          comboItem.iditemservico ===
                                                          item.iditemservico
                                                      )
                                                        ? "rgba(0, 161, 169, 0.01)"
                                                        : "rgba(221,227,234,0.1)",
                                                    height: "100%",
                                                    borderColor:
                                                      comboItemsAppointmented.find(
                                                        (comboItem) =>
                                                          comboItem.iditemservico ===
                                                          item.iditemservico
                                                      )
                                                        ? theme.palette.primary
                                                            .main
                                                        : theme.palette.neutral
                                                            .main,
                                                    transition:
                                                      theme.transitions.create(
                                                        ["border"],
                                                        {
                                                          duration:
                                                            theme.transitions
                                                              .duration
                                                              .standard,
                                                        }
                                                      ),
                                                  }}
                                                >
                                                  <CardContent>
                                                    <Grid container spacing={1}>
                                                      {item.itemservico
                                                        .preparo && (
                                                        <Grid
                                                          item
                                                          lg={12}
                                                          md={12}
                                                          sm={12}
                                                          xs={12}
                                                          mt={2}
                                                        >
                                                          <Typography
                                                            fontSize={14}
                                                            sx={{
                                                              color: (theme) =>
                                                                theme.palette
                                                                  .onPrimaryContainer
                                                                  .main,
                                                              fontWeight: 500,
                                                              mb: 1,
                                                            }}
                                                          >
                                                            Preparo
                                                          </Typography>

                                                          <Alert
                                                            color="warning"
                                                            icon={false}
                                                          >
                                                            {formatPreparation(
                                                              item.itemservico
                                                                .preparo
                                                            )}
                                                          </Alert>
                                                        </Grid>
                                                      )}

                                                      {item.informacoes && (
                                                        <Grid
                                                          item
                                                          lg={12}
                                                          md={12}
                                                          sm={12}
                                                          xs={12}
                                                          mt={2}
                                                        >
                                                          <Alert color="warning">
                                                            <AlertTitle>
                                                              Instruções
                                                            </AlertTitle>
                                                            {item.informacoes}
                                                          </Alert>
                                                        </Grid>
                                                      )}
                                                    </Grid>
                                                  </CardContent>
                                                </Card>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </StepContent>
                                      </Step>
                                    )
                                  )
                                )
                              )}
                            </Stepper>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Card
                  sx={{
                    height: "100%",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      // height: "90%",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {nextItemToAppointment &&
                        nextItemToAppointment.agendamentoativo ? (
                          <Typography
                            fontSize={16}
                            sx={{
                              color: (theme) => theme.palette.primary.main,
                              fontWeight: 600,
                              mb: 1,
                              textAlign: "center",
                            }}
                          >
                            Você está agendando o item:{" "}
                            {nextItemToAppointment.itemservico.nome}
                          </Typography>
                        ) : (
                          <Typography
                            fontSize={18}
                            sx={{
                              color: (theme) => theme.palette.primary.main,
                              fontWeight: 600,
                              mb: 1,
                              mt: 2,
                              textAlign: "center",
                            }}
                          >
                            Você agendou todos os itens do combo!
                          </Typography>
                        )}
                      </Grid>
                      {servicePackage &&
                        clinic &&
                        servicePackage.itenspromocionais.length > 0 &&
                        nextItemToAppointment && (
                          <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Card variant="outlined">
                                <ListItemButton
                                  selected={true}
                                  role={undefined}
                                  sx={{
                                    borderWidth: 1,
                                  }}
                                >
                                  <ListItemIcon>
                                    <Radio
                                      edge="start"
                                      checked={true}
                                      tabIndex={-1}
                                      disableRipple
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={formatName(
                                      nextItemToAppointment.itemservico.nome
                                    )}
                                    secondary={formatName(
                                      nextItemToAppointment.itemservico
                                        .categoriaservico.nome
                                    )}
                                  />
                                </ListItemButton>
                              </Card>
                            </Grid>
                            {nextItemToAppointment.itemsadicionais &&
                              promotionalItemAdditional &&
                              promotionalItemAdditional.length > 0 && (
                                <>
                                  {promotionalItemAdditional.map((item) => (
                                    <Grid
                                      key={item.iditempromocional}
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                    >
                                      <Card variant="outlined">
                                        <ListItemButton
                                          role={undefined}
                                          selected={true}
                                          sx={{
                                            borderWidth: 1,
                                          }}
                                        >
                                          <ListItemIcon>
                                            <Radio
                                              edge="start"
                                              checked={true}
                                              tabIndex={-1}
                                              disableRipple
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={formatName(
                                              item.itemservico.nome
                                            )}
                                            secondary={formatName(
                                              item.itemservico.categoriaservico
                                                .nome
                                            )}
                                          />
                                        </ListItemButton>
                                      </Card>
                                    </Grid>
                                  ))}
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    mt={2}
                                  >
                                    <Alert color="warning">
                                      Os itens acima serão agendados juntos ao
                                      item{" "}
                                      {nextItemToAppointment.itemservico.nome}
                                    </Alert>
                                  </Grid>
                                </>
                              )}
                            {nextItemToAppointment.informacoes && (
                              <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                                <Alert color="warning" icon={false}>
                                  {nextItemToAppointment.informacoes}
                                </Alert>
                              </Grid>
                            )}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Stack mt={2} mb={2}>
                                <Typography
                                  fontSize={14}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Clínica: {clinic.nome}
                                </Typography>
                                <Typography
                                  fontSize={14}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Endereço: {formatClinicAddress(clinic)}
                                </Typography>
                              </Stack>
                              {clinic.info_paciente !== "" && (
                                <Alert color="error" icon={false}>
                                  <AlertTitle>
                                    Informações ao paciente
                                  </AlertTitle>
                                  {clinic.info_paciente}
                                </Alert>
                              )}
                            </Grid>
                          </>
                        )}
                    </Grid>
                    <Grid container mt={4}>
                      {serviceGroup &&
                        clinic &&
                        nextItemToAppointment &&
                        serviceGroup.nome.toLowerCase() === "combos" && (
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            justifyContent="end"
                            display="flex"
                            mt={2}
                          >
                            {nextItemToAppointment.agendamentoativo ? (
                              <Button
                                size="large"
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() =>
                                  goToAppointmentNextStep(nextItemToAppointment)
                                }
                              >
                                Clique aqui para continuar
                              </Button>
                            ) : (
                              <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => goToHome()}
                              >
                                Continuar agendando
                              </Button>
                            )}
                          </Grid>
                        )}

                      {servicePackage && !nextItemToAppointment && (
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                          justifyContent="end"
                          display="flex"
                          mt={2}
                        >
                          <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => goToDashboard()}
                          >
                            Ver meus agendamentos
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}

          <Grid item lg={12} xs={12} mt={4}>
            <Typography
              fontSize={14}
              sx={{
                color: (theme) => theme.palette.onPrimaryContainer.main,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Prezado cliente caso não encontre a sua especialidade médica ou
              exame entre em contato pelo {process.env.REACT_APP_PHONE}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
