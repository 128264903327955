import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import { AppointmentType } from "../../types";
import {
  createUseAppointmentByIdKey,
  createUseAppointmentByResponsibleIdKey,
  createUseAppointmentKey,
} from "./key";

export const useAppointments = (
  {
    patientId,
    clinicId,
  }: {
    patientId: string;
    clinicId?: string;
  },
  options?: UseQueryOptions<AppointmentType[]>
) => {
  return useQuery(
    createUseAppointmentKey({
      patientId,
      clinicId,
    }),
    () =>
      api
        .get(
          `/user-patients/${patientId}/appointment${
            clinicId ? `?idclinica=${clinicId}` : ""
          }`
        )
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      refetchOnWindowFocus: false
    }
  );
};

export const useAppointmentsByResponsibleId = (
  {
    patientId,
  }: {
    patientId: string;
  },
  options?: UseQueryOptions<AppointmentType[]>
) => {
  return useQuery(
    createUseAppointmentByResponsibleIdKey({
      patientId,
    }),
    () =>
      api
        .post("/user-patients/dependents/appointments", {
          idresponsavel: patientId,
        })
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      refetchOnWindowFocus: false
    }
  );
};

export const useAppointmentById = (
  {
    appointmentId,
  }: {
    appointmentId: string;
  },
  options?: UseQueryOptions<AppointmentType>
) => {
  return useQuery(
    createUseAppointmentByIdKey({
      appointmentId,
    }),
    () =>
      api
        .get(`/user-patients/appointments/${appointmentId}`)
        .then((res) => res.data.data),
    {
      ...options,
      retry: 1,
      refetchOnWindowFocus: false
    }
  );
};
