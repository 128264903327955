import {
  Box,
  Container,
  Grid,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppointmentAdsCard from "../../../../components/AppointmentAdsCard";
import { Footer } from "../../../../components/Footer";
import { useAppointment } from "../../../../hooks/useAppointment";
import { useAuthenticatedUser } from "../../../../store/user";
import { AppointmentStepSchedule } from "../../../Appointment/components/AppointmentStepSchedule";
import { HomeNavbar } from "../../../Home/components/HomeNavbar";
import { AppointmentStepAccount } from "../AppointmentStepAccount";
import { AppointmentStepPromotionalItem } from "../AppointmentStepPromotionalItem";

export default function AppointmentServicePackageItem() {
  const theme = useTheme();
  const {
    clinic,
    serviceGroup,
    serviceItem,
    appointmentStep,
    professional,
    servicePackage,
  } = useAppointment();
  const matchesSM = useMediaQuery(theme.breakpoints.down("md"));

  const { data: user } = useAuthenticatedUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!serviceGroup || !servicePackage) {
      navigate("/");
    }
  }, [serviceGroup, servicePackage, navigate]);

  return (
    <Box
      minHeight="100vh"
      id="scroll-container"
      position="relative"
      sx={{
        pb: 2,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <HomeNavbar dark={true} />
      <LinearProgress color="secondary" variant="determinate" value={90} />
      <Box
        minHeight="100vh"
        position="relative"
        sx={{
          pb: 4,
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            minHeight: matchesSM ? "calc(100vh - 200px)" : "calc(90vh - 64px)",
          }}
        >
          <Grid container mt={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AppointmentAdsCard />
            </Grid>
          </Grid>

          {/* service group, clinic, service item */}
          {clinic && servicePackage && appointmentStep >= 0 && (
            <AppointmentStepPromotionalItem />
          )}

          {/* profissional e agenda */}
          {clinic && serviceItem && appointmentStep > 0 && (
            <>
              <Box
                sx={{
                  height: 2,
                  width: "100%",
                  mt: 8,
                  backgroundColor: (theme) => theme.palette.secondary.main,
                }}
              />
              <AppointmentStepSchedule />
            </>
          )}

          {/* account and confirm */}
          {clinic &&
            serviceItem &&
            professional &&
            appointmentStep > 1 &&
            !!user && (
              <>
                <Box
                  sx={{
                    height: 2,
                    width: "100%",
                    mt: 8,
                    backgroundColor: (theme) => theme.palette.secondary.main,
                  }}
                />
                <AppointmentStepAccount />
              </>
            )}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
