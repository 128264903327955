import { QueryKey } from "react-query";

export const createUseServiceItemKey = ({
  clinicId,
  searchTerm,
  serviceGroupId,
}: {
  serviceGroupId: string;
  searchTerm: string;
  clinicId: string;
}): QueryKey => ["useServiceItem", clinicId, searchTerm, serviceGroupId];
