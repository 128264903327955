import { Box, Container, Typography } from "@mui/material";
import { useAppointmentWebSetup } from "../../store/setup";

export const Footer = () => {
  const { data: setup } = useAppointmentWebSetup();

  return (
    <Box
      sx={{
        minHeight: "20vh",
        display: "flex",
        alignItems: "end",
        p: 2,
        justifyContent: "end",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          textAlign: "center",
        }}
      >
        {setup && setup.configs.general.text_footer ? (
          <Typography
            component="h1"
            variant="h3"
            dangerouslySetInnerHTML={{
              __html: setup.configs.general.text_footer,
            }}
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontSize: 16,
            }}
          />
        ) : (
          <Typography
            component="h1"
            variant="h3"
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontSize: 16,
            }}
          >
          </Typography>
        )}
      </Container>
    </Box>
  );
};
