import { styled } from "@mui/material";

export const MainContainer = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  width: "100%",
  minHeight: "100vh",
  flexGrow: 1,
}));
