import { useQuery, UseQueryOptions } from "react-query";
import { api } from "../../services/api";
import { IServiceGroup } from "../../types";
import { createUseServiceGroupKey } from "./keys";

export const useServiceGroups = (
  options?: UseQueryOptions<IServiceGroup[]>
) => {
  return useQuery(
    createUseServiceGroupKey(),
    () =>
      api.get(`/users-patients/service-groups`).then((res) => {
        return res.data.data.service_groups;
      }),
    {
      ...options,
      retry: 1,
      cacheTime: 1000 * 1 * 60,
      refetchOnWindowFocus: false
    }
  );
};
