import { QueryKey } from "react-query";

export const createUseUserAuthKey = (): QueryKey => ["useUserAuthKey"];

export const createUseApplicationIdKey = (): QueryKey => [
  "useApplicationIdKey",
];

export const createUseDependenceKey = (cpf: string): QueryKey => [
  "useDependenceKey",
  cpf,
];
