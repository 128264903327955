import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Stack,
  StepIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { CalendarBlank } from "phosphor-react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

import DoctorFemIcon from "../../../../assets/images/med-fem.jpg";
import DoctorMascIcon from "../../../../assets/images/med-masc.jpg";
import { RegisterDependentModal } from "../../../../components/RegiterDependentModal";
import { useAppointment } from "../../../../hooks/useAppointment";
import { useApplicationId, useAuthenticatedUser } from "../../../../store/user";
import { formatClinicAddress, formatName } from "../../../../utils/masks";
import { singularize, singularizeWithArticle } from "../../../../utils/roles";

export const AppointmentStepAccount = () => {
  const theme = useTheme();
  const {
    professional,
    serviceItem,
    serviceGroup,
    dateSelected,
    timeType,
    clinic,
    appointmentStep,
    account,
    servicePackage,
    dependent,
    handleCreateAppointment,
    isCreatingAppointment,
    appointmentType,
    paymentType,
    handleChangeAppointmentStep,
    handleChangeAppointmentServiceItem,
    handleChangeAppointmentProfessionalClear,
  } = useAppointment();

  const queryClient = useQueryClient();

  const [registerUser, setRegisterUser] = useState<boolean>(false);

  const { data: user } = useAuthenticatedUser();

  const { data: applicationId } = useApplicationId({
    enabled: !!user,
  });

  const handleConfirm = async () => {
    try {
      if (paymentType === "presential") {
        console.log("Accoun", account);
        const result = await handleCreateAppointment({
          applicationId: applicationId ? applicationId.idusuario : "",
          email: user
            ? user.email !== ""
              ? user.email
              : user.paciente.email
            : "",
          patientId:
            account === 0 && user
              ? user.paciente.idpaciente
              : dependent
              ? dependent.idpaciente
              : "",
          serviceChannelId:
            account === 0 && user
              ? user.paciente.idcanalatendimento
              : dependent
              ? dependent?.idcanalatendimento
              : "",
          userId: account === 1 && user ? user.paciente.idpaciente : "",
          isCombo: true,
        });

        if (result) {
          toast.success("Agendamento realizado com sucesso", {
            position: "top-right",
          });

          handleChangeAppointmentServiceItem(null);
          handleChangeAppointmentProfessionalClear();
          handleChangeAppointmentStep(0);
          queryClient.invalidateQueries(["useServicePackageById"]);
          queryClient.invalidateQueries(["useAppointment"]);

          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 300);
        }
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      }
    }
  };

  return (
    <Grid
      container
      mt={2}
      spacing={2}
      justifyContent="center"
      display="flex"
      component="section"
      id="tipo"
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          component="form"
          display="flex"
          noValidate
          autoComplete="off"
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
            display="flex"
            mb={2}
          >
            <Stack direction="row" spacing={1} mt={1} alignItems="center">
              <StepIcon icon="3" active={appointmentStep === 2} />
              <Typography
                component="h1"
                variant="h6"
                mb={2}
                fontWeight={600}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontSize: 18,
                }}
              >
                Confirme as informações
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
            display="flex"
          >
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card
                  sx={{
                    backgroundColor: "rgba(221,227,234,0.2)",
                    height: "100%",
                  }}
                >
                  <CardContent>
                    <Typography
                      fontSize={16}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 600,
                        mb: 2,
                        textAlign: "center",
                      }}
                    >
                      Resumo e confirmação
                    </Typography>
                    <Grid container spacing={2}>
                      {professional && clinic && (
                        <>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Avatar
                              src={
                                professional.sexoprofissional?.toLowerCase() ===
                                "masculino"
                                  ? DoctorMascIcon
                                  : DoctorFemIcon
                              }
                              sx={{
                                width: 56,
                                height: 56,
                              }}
                              style={{
                                border: `0.1px solid ${theme.palette.primary.main}`,
                              }}
                              alt="professional profile"
                            />
                          </Grid>
                          <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography
                              fontSize={18}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                                fontWeight: "bold",
                                mb: 1,
                              }}
                            >
                              {formatName(professional.nomeprofissional)}
                            </Typography>
                            {clinic && (
                              <Stack mt={2}>
                                <Typography
                                  fontSize={12}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Clínica: {clinic.nome}
                                </Typography>
                                <Typography
                                  fontSize={12}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Endereço: {formatClinicAddress(clinic)}
                                </Typography>
                              </Stack>
                            )}
                            <Stack mt={2}>
                              {timeType === "time" ? (
                                <Typography
                                  fontSize={14}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`Data e hora: ${format(
                                    new Date(
                                      dateSelected.toISOString().slice(0, -1)
                                    ),
                                    "dd/MM/yyyy '`as' HH:mm"
                                  )}`}
                                </Typography>
                              ) : (
                                <>
                                  <Typography
                                    fontSize={14}
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.onPrimaryContainer.main,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`Data e hora: ${format(
                                      new Date(
                                        new Date(
                                          professional
                                            ? professional.timetable[0]
                                                .timeLabel.date
                                            : new Date()
                                        )
                                          .toISOString()
                                          .slice(0, -1)
                                      ),
                                      "dd/MM/yyyy 'a partir de' HH:mm"
                                    )} - Por ordem de chegada`}
                                  </Typography>
                                  {timeType === "vacancy" && (
                                    <Typography
                                      fontSize={14}
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.error.main,
                                        mt: 1,
                                      }}
                                    >
                                      Obs: Chegar 15 min antes do horário de
                                      início
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Divider
                      sx={{
                        width: "100%",
                        mt: 2,
                      }}
                    />
                    <Grid container mt={3}>
                      {appointmentType &&
                        appointmentType === "particular" &&
                        serviceItem &&
                        serviceGroup &&
                        servicePackage && (
                          <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                mb={2}
                              >
                                <Typography
                                  fontSize={16}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Tipo de agendamento:
                                </Typography>

                                <Chip label="Particular" color="primary" />
                              </Stack>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                fontSize={16}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: 500,
                                  mb: 1,
                                }}
                              >
                                Especialidade: <b>{serviceItem.nome}</b>
                              </Typography>
                              <Alert color="info">
                                <AlertTitle>
                                  Combo: {servicePackage.titulo}
                                </AlertTitle>
                                Você está agendamento o item {serviceItem.nome},
                                que está incluso no combo{" "}
                                {servicePackage.titulo.toLowerCase()}
                              </Alert>
                            </Grid>

                            {serviceItem.preparo &&
                              serviceItem.preparo !== "" && (
                                <>
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    mt={2}
                                  >
                                    <Typography
                                      fontSize={16}
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.onPrimaryContainer.main,
                                        fontWeight: 500,
                                        mb: 1,
                                      }}
                                    >
                                      Preparo para{" "}
                                      {singularizeWithArticle(
                                        serviceGroup.nome.toLowerCase()
                                      )}
                                    </Typography>
                                    <Alert color="warning" icon={false}>
                                      {serviceItem.preparo}
                                    </Alert>
                                  </Grid>
                                </>
                              )}
                          </>
                        )}

                      {clinic && clinic.info_paciente !== "" && (
                        <>
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                                fontWeight: 500,
                                mb: 1,
                              }}
                            >
                              Informações ao paciente
                            </Typography>
                            <Alert color="error" icon={false}>
                              {clinic.info_paciente}
                            </Alert>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container mt={6}>
          {account === 0 && user && serviceGroup && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                startIcon={<CalendarBlank />}
                fullWidth
                variant="contained"
                disabled={isCreatingAppointment}
                size="large"
                onClick={() => handleConfirm()}
                color="secondary"
              >
                {isCreatingAppointment
                  ? `Aguarde! Concluíndo agendamento`
                  : `Confirmar ${singularize(serviceGroup.nome.toLowerCase())}`}
              </Button>
            </Grid>
          )}
          {account === 1 && dependent && serviceGroup && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                startIcon={<CalendarBlank />}
                fullWidth
                disabled={isCreatingAppointment}
                onClick={() => handleConfirm()}
                variant="contained"
                size="large"
                color="secondary"
              >
                {isCreatingAppointment
                  ? `Aguarde! Concluíndo agendamento`
                  : `Confirmar ${singularize(serviceGroup.nome.toLowerCase())}`}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <RegisterDependentModal
        open={registerUser}
        onClose={() => setRegisterUser(false)}
      />
    </Grid>
  );
};
