import {
  Alert,
  Button,
  DialogContentText,
  Grid,
  Stack,
  StepIcon,
  Typography,
} from "@mui/material";
import { Buildings } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { AuthModal } from "../../../../components/AuthModal";
import { ServicePackageCard } from "../../../../components/ServicePackageCard";
import { useAppointment } from "../../../../hooks/useAppointment";
import { useServicePackage } from "../../../../store/servicePackage";
import { useAuthenticatedUser } from "../../../../store/user";

export const ListServicePackage = () => {
  const { clinic, handleChangeAppointmentServicePackage, servicePackage } =
    useAppointment();
  const { data: user, isLoading } = useAuthenticatedUser();
  const navigate = useNavigate();

  const { data: servicePackages } = useServicePackage(
    {
      clinicId: clinic ? clinic.idclinica : "",
    },
    {
      enabled: !!clinic,
    }
  );

  const goToAppointmentPage = () => {
    return navigate(-1);
  };

  const handleLogin = () => {
    navigate(`${servicePackage?.titulo.replace(" ", "-").toLowerCase()}`, {
      state: {
        login: true,
      },
      preventScrollReset: true,
    });
  };

  const handleRegister = () => {
    navigate(`${servicePackage?.titulo.replace(" ", "-").toLowerCase()}`, {
      state: {
        register: true,
      },
      preventScrollReset: true,
    });
  };

  return (
    <Grid
      container
      mt={2}
      spacing={2}
      justifyContent="center"
      display="flex"
      component="section"
      id="tipo"
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          component="form"
          display="flex"
          noValidate
          autoComplete="off"
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
            display="flex"
          >
            <Stack direction="row" spacing={1} mt={1} alignItems="center">
              <StepIcon icon="1" active={true} />
              <Typography
                component="h1"
                variant="h6"
                mb={2}
                fontWeight={600}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontSize: 18,
                }}
              >
                Selecione o melhor combo para você
              </Typography>
            </Stack>
          </Grid>

          {clinic && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Alert color="info" icon={false}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Buildings size={24} />
                  <Typography
                    fontSize={16}
                    sx={{
                      color: (theme) => theme.palette.onPrimaryContainer.main,
                      fontWeight: 500,
                    }}
                  >
                    Clínica: <b>{clinic.nome}</b>
                  </Typography>
                  <Button variant="text" onClick={() => goToAppointmentPage()}>
                    Alterar
                  </Button>
                </Stack>
              </Alert>
            </Grid>
          )}

          {servicePackages && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                {servicePackages.map((servicePackage) => (
                  <Grid
                    key={servicePackage.idcombo}
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <ServicePackageCard servicePackageItem={servicePackage} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <AuthModal
        open={!!servicePackage && !user && !isLoading}
        title="Você está agendando um combo"
        onClickLogin={() => handleLogin()}
        onClickRegister={() => handleRegister()}
        onClose={() => handleChangeAppointmentServicePackage(null)}
      >
        <DialogContentText
          sx={{
            fontWeight: 600,
          }}
        >
          Informações:
        </DialogContentText>
        <ul>
          <li>
            <DialogContentText>
              Combo: {servicePackage?.titulo}
            </DialogContentText>
          </li>
          <li>
            <DialogContentText>Clínica: {clinic?.nome}</DialogContentText>
          </li>
        </ul>
      </AuthModal>
    </Grid>
  );
};
