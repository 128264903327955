import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  StepIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { CalendarBlank } from "phosphor-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import DoctorFemIcon from "../../../../assets/images/med-fem.jpg";
import DoctorMascIcon from "../../../../assets/images/med-masc.jpg";
import { RegisterDependentModal } from "../../../../components/RegiterDependentModal";
import { useAppointment } from "../../../../hooks/useAppointment";
import {
  useApplicationId,
  useAuthenticatedUser,
  useDependents,
} from "../../../../store/user";
import {
  formatClinicAddress,
  formatCurrency,
  formatName,
} from "../../../../utils/masks";
import { singularize, singularizeWithArticle } from "../../../../utils/roles";

import ReactGA from "react-ga";

export const AppointmentStepAccount = () => {
  const theme = useTheme();
  const {
    professional,
    serviceItem,
    serviceItemHealtPlan,
    serviceGroup,
    dateSelected,
    timeType,
    clinic,
    appointmentStep,
    account,
    healthPlan,
    handleChangeAppointmentAccount,
    dependent,
    handleCreateAppointment,
    isCreatingAppointment,
    appointmentType,
    handleReset,
  } = useAppointment();

  const [registerUser, setRegisterUser] = useState<boolean>(false);
  const [showPrepar, setShowPrepar] = useState<boolean>(true);

  const { data: user } = useAuthenticatedUser();
  const { data: dependents } = useDependents(
    {
      cpfresponsavel: user && user.paciente.cpf ? user.paciente.cpf : "",
    },
    {
      enabled: !!user,
    }
  );
  const { data: applicationId } = useApplicationId({
    enabled: !!user,
  });
  const navigate = useNavigate();

  const handleConfirm = async () => {
    try {
      const result = await handleCreateAppointment({
        applicationId: applicationId ? applicationId.idusuario : "",
        email: user
          ? user.email !== ""
            ? user.email
            : user.paciente.email
          : "",
        patientId:
          account === 0 && user
            ? user.paciente.idpaciente
            : dependent
            ? dependent.idpaciente
            : "",
        serviceChannelId:
          account === 0 && user
            ? user.paciente.idcanalatendimento
            : dependent
            ? dependent?.idcanalatendimento
            : "",
        userId: account === 1 && user ? user.paciente.idpaciente : "",
      });

      if (result) {
        toast.success("Agendamento realizado com sucesso", {
          position: "top-right",
        });

        ReactGA.event({
          category: "agendamento-web",
          action: "confirmation",
          label: "consulta-confirmada", //6
        });

        handleReset();

        navigate(`${result.idconsulta}/summary`, { replace: true });
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.error.message);
      }
    }
  };

  useEffect(() => {
    ReactGA.event({
      category: "agendamento-web",
      action: "start",
      label: "confirmar-consulta", //5
    });
  }, []);

  return (
    <Grid
      container
      mt={2}
      spacing={2}
      justifyContent="center"
      display="flex"
      component="section"
      id="tipo"
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          component="form"
          display="flex"
          noValidate
          autoComplete="off"
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
            display="flex"
            mb={2}
          >
            <Stack direction="row" spacing={1} mt={1} alignItems="center">
              <StepIcon icon="3" active={appointmentStep === 2} />
              <Typography
                component="h1"
                variant="h6"
                mb={2}
                fontWeight={600}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontSize: 18,
                }}
              >
                Confirme as informações
              </Typography>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography
              component="h1"
              variant="h6"
              mb={2}
              fontWeight={600}
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: 18,
              }}
            >
              Para quem é a consulta?
            </Typography>
            <List
              sx={{
                width: "100%",
              }}
            >
              <Grid container spacing={2}>
                {user && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card variant="outlined">
                      <ListItemButton
                        selected={account === 0}
                        role={undefined}
                        onClick={() => {
                          handleChangeAppointmentAccount({
                            account: 0,
                            dependent: null,
                          });
                        }}
                        sx={{
                          borderWidth: 1,
                        }}
                      >
                        <ListItemIcon>
                          <Radio
                            edge="start"
                            checked={account === 0}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={formatName(user.paciente.nomepaciente)}
                        />
                      </ListItemButton>
                    </Card>
                  </Grid>
                )}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card variant="outlined">
                    <ListItemButton
                      selected={account === 1}
                      role={undefined}
                      onClick={() => {
                        handleChangeAppointmentAccount({
                          account: 1,
                          dependent: null,
                        });
                      }}
                      sx={{
                        borderWidth: 1,
                      }}
                    >
                      <ListItemIcon>
                        <Radio
                          edge="start"
                          checked={account === 1}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary="Para outra pessoa" />
                    </ListItemButton>
                  </Card>
                  <Collapse in={account === 1}>
                    {dependents && dependents.length > 0 ? (
                      <List
                        component="div"
                        sx={{
                          mt: 2,
                          ml: 3,
                        }}
                      >
                        <Grid container spacing={2}>
                          {dependents.map((dep) => (
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              key={dep.idpaciente}
                            >
                              <Card variant="outlined">
                                <ListItemButton
                                  selected={
                                    account === 1 &&
                                    dependent &&
                                    dependent.idpaciente === dep.idpaciente
                                      ? true
                                      : false
                                  }
                                  role={undefined}
                                  onClick={() => {
                                    handleChangeAppointmentAccount({
                                      account: 1,
                                      dependent: dep,
                                    });
                                  }}
                                  sx={{
                                    borderWidth: 1,
                                  }}
                                >
                                  <ListItemIcon>
                                    <Radio
                                      edge="start"
                                      checked={
                                        account === 1 &&
                                        dependent &&
                                        dependent.idpaciente === dep.idpaciente
                                          ? true
                                          : false
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={dep.nomepaciente} />
                                </ListItemButton>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </List>
                    ) : (
                      <Card
                        sx={{
                          mt: 2,
                        }}
                      >
                        <CardContent>
                          <Typography
                            component="h1"
                            variant="h6"
                            fontWeight={600}
                            sx={{
                              color: (theme) => theme.palette.primary.main,
                              fontSize: 16,
                              textAlign: "center",
                            }}
                          >
                            Você não possui nenhum dependente cadastrado
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                    <Typography
                      component="h1"
                      variant="h6"
                      mt={3}
                      mb={1}
                      fontWeight={600}
                      sx={{
                        color: (theme) => theme.palette.text.primary,
                        fontSize: 14,
                      }}
                    >
                      Você pode cadastrar uma nova pessoa (dependente)
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setRegisterUser(true)}
                    >
                      Clique aqui para cadastrar
                    </Button>
                  </Collapse>
                </Grid>
                {appointmentType &&
                  appointmentType === "particular" &&
                  serviceItem &&
                  serviceGroup && (
                    <>
                      {serviceItem.preparo !== "" && (
                        <>
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}
                              mb={1}
                            >
                              <Typography
                                fontSize={16}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: 500,
                                  mb: 1,
                                }}
                              >
                                Preparo para{" "}
                                {singularizeWithArticle(
                                  serviceGroup.nome.toLowerCase()
                                )}
                              </Typography>
                              <Button
                                onClick={() => setShowPrepar((old) => !old)}
                              >
                                {showPrepar ? `Ocultar preparo` : `Ver preparo`}
                              </Button>
                            </Stack>
                            <Collapse in={showPrepar}>
                              <Alert color="warning" icon={false}>
                                {serviceItem.preparo}
                              </Alert>
                            </Collapse>
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                {appointmentType &&
                  appointmentType === "convenio" &&
                  healthPlan &&
                  serviceItemHealtPlan &&
                  serviceGroup && (
                    <>
                      {serviceItemHealtPlan.itemservico.preparo !== "" && (
                        <>
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                                fontWeight: 500,
                                mb: 1,
                              }}
                            >
                              Preparo para{" "}
                              {singularizeWithArticle(
                                serviceGroup.nome.toLowerCase()
                              )}
                            </Typography>
                            <Alert color="warning" icon={false}>
                              {serviceItemHealtPlan.itemservico.preparo}
                            </Alert>
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                {clinic && clinic.info_paciente !== "" && (
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Typography
                        fontSize={16}
                        sx={{
                          color: (theme) =>
                            theme.palette.onPrimaryContainer.main,
                          fontWeight: 500,
                          mb: 1,
                        }}
                      >
                        Informações ao paciente
                      </Typography>
                      <Alert color="error" icon={false}>
                        {clinic.info_paciente}
                      </Alert>
                    </Grid>
                  </>
                )}
              </Grid>
            </List>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            display="flex"
          >
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card
                  sx={{
                    backgroundColor: "rgba(221,227,234,0.2)",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <CardContent>
                    <Typography
                      fontSize={16}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 600,
                        mb: 2,
                        textAlign: "center",
                      }}
                    >
                      Resumo e confirmação
                    </Typography>
                    <Grid container spacing={2}>
                      {professional && clinic && (
                        <>
                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={12}
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Avatar
                              src={
                                professional.sexoprofissional?.toLowerCase() ===
                                "masculino"
                                  ? DoctorMascIcon
                                  : DoctorFemIcon
                              }
                              sx={{
                                width: 56,
                                height: 56,
                              }}
                              style={{
                                border: `0.1px solid ${theme.palette.primary.main}`,
                              }}
                              alt="professional profile"
                            />
                          </Grid>
                          <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Typography
                              fontSize={18}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                                fontWeight: "bold",
                                mb: 1,
                              }}
                            >
                              {formatName(professional.nomeprofissional)}
                            </Typography>
                            {clinic && (
                              <Stack mt={2}>
                                <Typography
                                  fontSize={12}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Clínica: {clinic.nome}
                                </Typography>
                                <Typography
                                  fontSize={12}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Endereço: {formatClinicAddress(clinic)}
                                </Typography>
                              </Stack>
                            )}
                            <Stack mt={2}>
                              {timeType === "time" ? (
                                <Typography
                                  fontSize={14}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`Data e hora: ${format(
                                    new Date(
                                      dateSelected.toISOString().slice(0, -1)
                                    ),
                                    "dd/MM/yyyy '`as' HH:mm"
                                  )}`}
                                </Typography>
                              ) : (
                                <>
                                  <Typography
                                    fontSize={14}
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.onPrimaryContainer.main,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`Data e hora: ${format(
                                      new Date(
                                        new Date(
                                          professional
                                            ? professional.timetable[0]
                                                .timeLabel.date
                                            : new Date()
                                        )
                                          .toISOString()
                                          .slice(0, -1)
                                      ),
                                      "dd/MM/yyyy 'a partir de' HH:mm"
                                    )} - Por ordem de chegada`}
                                  </Typography>
                                  {timeType === "vacancy" && (
                                    <Typography
                                      fontSize={14}
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.error.main,
                                        mt: 1,
                                      }}
                                    >
                                      Obs: Chegar 15 min antes do horário de
                                      início
                                    </Typography>
                                  )}
                                </>
                              )}
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Divider
                      sx={{
                        width: "100%",
                        mt: 2,
                      }}
                    />
                    <Grid container mt={3}>
                      {appointmentType &&
                        appointmentType === "particular" &&
                        serviceItem &&
                        serviceGroup && (
                          <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                mb={2}
                              >
                                <Typography
                                  fontSize={16}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Tipo de agendamento:
                                </Typography>

                                <Chip label="Particular" color="primary" />
                              </Stack>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                fontSize={16}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: 500,
                                  mb: 1,
                                }}
                              >
                                Especialidade: <b>{serviceItem.nome}</b>
                              </Typography>
                              <Alert color="info">
                                <AlertTitle>
                                  Valor d
                                  {singularizeWithArticle(
                                    serviceGroup.nome.toLowerCase()
                                  )}
                                  : {formatCurrency(Number(serviceItem.valor))}
                                </AlertTitle>
                                {serviceItem.gruposervico !== null ? (
                                  <>
                                    {serviceItem.gruposervico
                                      .tipospagamentos !== "" &&
                                      serviceItem.gruposervico.tipospagamentos}
                                  </>
                                ) : (
                                  serviceItem.categoriaservico.gruposervico && (
                                    <>
                                      {serviceItem.categoriaservico.gruposervico
                                        .tipospagamentos !== "" &&
                                        serviceItem.categoriaservico
                                          .gruposervico.tipospagamentos}
                                    </>
                                  )
                                )}
                              </Alert>
                            </Grid>
                          </>
                        )}
                      {appointmentType &&
                        appointmentType === "convenio" &&
                        healthPlan &&
                        serviceItemHealtPlan &&
                        serviceGroup && (
                          <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                mb={2}
                              >
                                <Typography
                                  fontSize={16}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Tipo de agendamento:
                                </Typography>

                                <Chip
                                  label={`Convênio - ${healthPlan.nome}`}
                                  color="secondary"
                                />
                              </Stack>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                fontSize={16}
                                sx={{
                                  color: (theme) =>
                                    theme.palette.onPrimaryContainer.main,
                                  fontWeight: 500,
                                  mb: 1,
                                }}
                              >
                                Especialidade:{" "}
                                <b>{serviceItemHealtPlan.itemservico.nome}</b>
                              </Typography>
                              <Alert color="info">
                                <AlertTitle>
                                  Valor d
                                  {singularizeWithArticle(
                                    serviceGroup.nome.toLowerCase()
                                  )}
                                  :{" "}
                                  {formatCurrency(
                                    Number(serviceItemHealtPlan.valor)
                                  )}
                                </AlertTitle>
                                {serviceItemHealtPlan.itemservico
                                  .gruposervico !== null ? (
                                  <>
                                    {serviceItemHealtPlan.itemservico
                                      .gruposervico.tipospagamentos !== "" &&
                                      serviceItemHealtPlan.itemservico
                                        .gruposervico.tipospagamentos}
                                  </>
                                ) : (
                                  serviceItemHealtPlan.itemservico
                                    .categoriaservico.gruposervico && (
                                    <>
                                      {serviceItemHealtPlan.itemservico
                                        .categoriaservico.gruposervico
                                        .tipospagamentos !== "" &&
                                        serviceItemHealtPlan.itemservico
                                          .categoriaservico.gruposervico
                                          .tipospagamentos}
                                    </>
                                  )
                                )}
                              </Alert>
                            </Grid>

                            {serviceItemHealtPlan.itemservico.preparo !==
                              "" && (
                              <>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  mt={2}
                                >
                                  <Typography
                                    fontSize={16}
                                    sx={{
                                      color: (theme) =>
                                        theme.palette.onPrimaryContainer.main,
                                      fontWeight: 500,
                                      mb: 1,
                                    }}
                                  >
                                    Preparo para{" "}
                                    {singularizeWithArticle(
                                      serviceGroup.nome.toLowerCase()
                                    )}
                                  </Typography>
                                  <Alert color="warning" icon={false}>
                                    {serviceItemHealtPlan.itemservico.preparo}
                                  </Alert>
                                </Grid>
                              </>
                            )}
                          </>
                        )}

                      {clinic && clinic.info_paciente !== "" && (
                        <>
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                                fontWeight: 500,
                                mb: 1,
                              }}
                            >
                              Informações ao paciente
                            </Typography>
                            <Alert color="error" icon={false}>
                              {clinic.info_paciente}
                            </Alert>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardContent>
                  <CardContent>
                    <Grid container mt={3}>
                      {account === 0 && user && serviceGroup && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            startIcon={<CalendarBlank />}
                            fullWidth
                            variant="contained"
                            disabled={isCreatingAppointment}
                            size="large"
                            onClick={() => handleConfirm()}
                            color="secondary"
                          >
                            {isCreatingAppointment
                              ? `Aguarde! Concluíndo agendamento`
                              : `Confirmar ${singularize(
                                  serviceGroup.nome.toLowerCase()
                                )}`}
                          </Button>
                        </Grid>
                      )}
                      {account === 1 && dependent && serviceGroup && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            startIcon={<CalendarBlank />}
                            fullWidth
                            disabled={isCreatingAppointment}
                            onClick={() => handleConfirm()}
                            variant="contained"
                            size="large"
                            color="secondary"
                          >
                            {isCreatingAppointment
                              ? `Aguarde! Concluíndo agendamento`
                              : `Confirmar ${singularize(
                                  serviceGroup.nome.toLowerCase()
                                )}`}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <RegisterDependentModal
        open={registerUser}
        onClose={() => setRegisterUser(false)}
      />
    </Grid>
  );
};
