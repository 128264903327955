import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Stack,
  StepIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { CalendarBlank, Check } from "phosphor-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { RegisterDependentModal } from "../../../../components/RegiterDependentModal";
import { useAppointment } from "../../../../hooks/useAppointment";
import { useAppointments } from "../../../../store/appointment";
import { useAuthenticatedUser, useDependents } from "../../../../store/user";
import {
  APPOINTMENT_STATUS,
  AppointmentType,
  IServiceItem,
} from "../../../../types";
import {
  formatClinicAddress,
  formatCurrency,
  formatName,
} from "../../../../utils/masks";
import { singularizeWithArticle } from "../../../../utils/roles";

export const ServicePackageAccount = () => {
  const theme = useTheme();
  const {
    serviceGroup,
    clinic,
    appointmentStep,
    account,
    handleChangeAppointmentAccount,
    dependent,
    isCreatingAppointment,
    servicePackage,
  } = useAppointment();

  const [registerUser, setRegisterUser] = useState<boolean>(false);

  const { data: user } = useAuthenticatedUser();
  const { data: dependents } = useDependents(
    {
      cpfresponsavel: user && user.paciente.cpf ? user.paciente.cpf : "",
    },
    {
      enabled: !!user,
    }
  );

  const id =
    account === 0 && user
      ? user.paciente.idpaciente
      : dependent
      ? dependent.idpaciente
      : "";

  const { data: appointments } = useAppointments(
    {
      patientId: id,
      clinicId: clinic ? clinic.idclinica : undefined,
    },
    {
      enabled: !!(account === 0
        ? user?.paciente.idpaciente
        : dependent?.idpaciente),
    }
  );

  const navigate = useNavigate();

  const comboItemsAppointmented: IServiceItem[] = [];
  const comboAppointmented: AppointmentType[] = [];
  const comboNotAppointmented: AppointmentType[] = [];

  if (appointments && servicePackage) {
    appointments.forEach((appointment) => {
      appointment.orderitems.forEach((item) => {
        if (item.idcombo && item.idcombo === servicePackage?.idcombo) {
          if (
            !(
              appointment.status === APPOINTMENT_STATUS.CANCELLED ||
              appointment.status === APPOINTMENT_STATUS.MISSED
            )
          ) {
            comboItemsAppointmented.push(item.itemservicos);
            comboAppointmented.push(appointment);
          } else {
            comboNotAppointmented.push(appointment);
          }
        }
      });
    });
  }

  const promotionalItemsServiceAppointmented =
    servicePackage?.itenspromocionais.filter((itemPromotional) => {
      return comboItemsAppointmented.find(
        (item) => item.iditemservico === itemPromotional.iditemservico
      );
    });

  const lastItemServiceAppointmented =
    promotionalItemsServiceAppointmented &&
    promotionalItemsServiceAppointmented.length > 0
      ? promotionalItemsServiceAppointmented[
          promotionalItemsServiceAppointmented.length - 1
        ]
      : null;

  const itemsEnabledToAppointment = servicePackage
    ? servicePackage.itenspromocionais.filter(
        (item) => item.itemservico.agendamento
      )
    : [];

  const lastItemToAppointmentIndex =
    lastItemServiceAppointmented && itemsEnabledToAppointment
      ? itemsEnabledToAppointment.findIndex(
          (item) =>
            item.iditempromocional ===
            lastItemServiceAppointmented?.iditempromocional
        )
      : -1;

  const nextItemToAppointment =
    servicePackage &&
    servicePackage.itenspromocionais.length > lastItemToAppointmentIndex &&
    itemsEnabledToAppointment.length > 0
      ? comboNotAppointmented.length === itemsEnabledToAppointment.length
        ? itemsEnabledToAppointment[lastItemToAppointmentIndex + 1]
        : comboNotAppointmented.length === 0
        ? itemsEnabledToAppointment[lastItemToAppointmentIndex + 1]
        : null
      : null;

  const handleConfirm = async () => {
    if (nextItemToAppointment && nextItemToAppointment.agendamentoativo) {
      const settled =
        comboAppointmented.length > 0 &&
        comboAppointmented.find((a) => a.status === APPOINTMENT_STATUS.SETTLED)
          ? true
          : false;
      const missed =
        comboNotAppointmented.length > 0 &&
        comboNotAppointmented.find(
          (a) => a.status === APPOINTMENT_STATUS.MISSED
        )
          ? true
          : false;

      if (settled && missed) {
        handleViewSummary(settled && missed);
      } else {
        navigate(`/agendamento-combo/item-servico`, { replace: true });
      }
    } else {
      const settled =
        comboAppointmented.length > 0 &&
        comboAppointmented.find((a) => a.status === APPOINTMENT_STATUS.SETTLED)
          ? true
          : false;
      const missed =
        comboNotAppointmented.length > 0 &&
        comboNotAppointmented.find(
          (a) => a.status === APPOINTMENT_STATUS.MISSED
        )
          ? true
          : false;

      handleViewSummary(settled && missed);
    }
  };

  const handleViewSummary = async (settled?: boolean) => {
    navigate(
      `/agendamento-combo/item-servico?summary=true${
        settled ? "&settled=true" : ""
      }`
    );
  };

  return (
    <Grid
      container
      mt={2}
      spacing={2}
      justifyContent="center"
      display="flex"
      component="section"
      id="tipo"
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          component="form"
          display="flex"
          noValidate
          autoComplete="off"
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            justifyContent="center"
            display="flex"
            mb={2}
          >
            <Stack direction="row" spacing={1} mt={1} alignItems="center">
              <StepIcon icon="3" active={appointmentStep === 2} />
              <Typography
                component="h1"
                variant="h6"
                mb={2}
                fontWeight={600}
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontSize: 18,
                }}
              >
                Confirme as informações
              </Typography>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography
              component="h1"
              variant="h6"
              mb={2}
              fontWeight={600}
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: 18,
              }}
            >
              Para quem é o combo?
            </Typography>
            <List
              sx={{
                width: "100%",
              }}
            >
              <Grid container spacing={2}>
                {user && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card variant="outlined">
                      <ListItemButton
                        selected={account === 0}
                        role={undefined}
                        onClick={() => {
                          handleChangeAppointmentAccount({
                            account: 0,
                            dependent: null,
                          });
                        }}
                        sx={{
                          borderWidth: 1,
                        }}
                      >
                        <ListItemIcon>
                          <Radio
                            edge="start"
                            checked={account === 0}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={formatName(user.paciente.nomepaciente)}
                        />
                      </ListItemButton>
                    </Card>
                  </Grid>
                )}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card variant="outlined">
                    <ListItemButton
                      selected={account === 1}
                      role={undefined}
                      onClick={() => {
                        handleChangeAppointmentAccount({
                          account: 1,
                          dependent: null,
                        });
                      }}
                      sx={{
                        borderWidth: 1,
                      }}
                    >
                      <ListItemIcon>
                        <Radio
                          edge="start"
                          checked={account === 1}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary="Para outra pessoa" />
                    </ListItemButton>
                  </Card>
                  <Collapse in={account === 1}>
                    {dependents && dependents.length > 0 ? (
                      <List
                        component="div"
                        sx={{
                          mt: 2,
                          ml: 3,
                        }}
                      >
                        <Grid container spacing={2}>
                          {dependents.map((dep) => (
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              key={dep.idpaciente}
                            >
                              <Card variant="outlined">
                                <ListItemButton
                                  selected={
                                    account === 1 &&
                                    dependent &&
                                    dependent.idpaciente === dep.idpaciente
                                      ? true
                                      : false
                                  }
                                  role={undefined}
                                  onClick={() => {
                                    handleChangeAppointmentAccount({
                                      account: 1,
                                      dependent: dep,
                                    });
                                  }}
                                  sx={{
                                    borderWidth: 1,
                                  }}
                                >
                                  <ListItemIcon>
                                    <Radio
                                      edge="start"
                                      checked={
                                        account === 1 &&
                                        dependent &&
                                        dependent.idpaciente === dep.idpaciente
                                          ? true
                                          : false
                                      }
                                      tabIndex={-1}
                                      disableRipple
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={dep.nomepaciente} />
                                </ListItemButton>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </List>
                    ) : (
                      <Card
                        sx={{
                          mt: 2,
                        }}
                      >
                        <CardContent>
                          <Typography
                            component="h1"
                            variant="h6"
                            fontWeight={600}
                            sx={{
                              color: (theme) => theme.palette.primary.main,
                              fontSize: 16,
                              textAlign: "center",
                            }}
                          >
                            Você não possui nenhum dependente cadastrado
                          </Typography>
                        </CardContent>
                      </Card>
                    )}

                    <Typography
                      component="h1"
                      variant="h6"
                      mt={3}
                      mb={1}
                      fontWeight={600}
                      sx={{
                        color: (theme) => theme.palette.text.primary,
                        fontSize: 14,
                      }}
                    >
                      Você pode cadastrar uma nova pessoa (dependente)
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setRegisterUser(true)}
                    >
                      Clique aqui para cadastrar
                    </Button>
                  </Collapse>
                </Grid>
              </Grid>
            </List>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            justifyContent="center"
            display="flex"
          >
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card
                  sx={{
                    backgroundColor: "rgba(221,227,234,0.2)",
                    height: "100%",
                  }}
                >
                  <CardContent>
                    <Typography
                      fontSize={16}
                      sx={{
                        color: (theme) => theme.palette.onPrimaryContainer.main,
                        fontWeight: 600,
                        mb: 2,
                        textAlign: "center",
                      }}
                    >
                      Resumo e confirmação
                    </Typography>
                    <Grid container spacing={2}>
                      {servicePackage && clinic && (
                        <>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              fontSize={18}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                                fontWeight: "bold",
                                mb: 1,
                              }}
                            >
                              {formatName(servicePackage.titulo)}
                            </Typography>
                            {clinic && (
                              <Stack mt={2}>
                                <Typography
                                  fontSize={12}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Clínica: {clinic.nome}
                                </Typography>
                                <Typography
                                  fontSize={12}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onPrimaryContainer.main,
                                    fontWeight: 500,
                                  }}
                                >
                                  Endereço: {formatClinicAddress(clinic)}
                                </Typography>
                              </Stack>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Divider
                      sx={{
                        width: "100%",
                        mt: 2,
                      }}
                    />
                    <Grid container mt={3}>
                      {serviceGroup && servicePackage && (
                        <>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                              fontSize={14}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onSurfaceVariant.main,
                                fontWeight: 500,
                              }}
                            >
                              Itens inclusos no combo:
                            </Typography>
                          </Grid>
                          {servicePackage.itenspromocionais.map((item) => (
                            <Grid
                              key={item.iditempromocional}
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Check color={theme.palette.primary.main} />
                                <Typography
                                  fontSize={14}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.onSurfaceVariant.main,
                                    fontWeight: 400,
                                  }}
                                >
                                  {formatName(item.itemservico.nome)}
                                </Typography>
                              </Stack>
                            </Grid>
                          ))}
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Alert color="info">
                              <AlertTitle>
                                Valor d
                                {singularizeWithArticle(
                                  serviceGroup.nome.toLowerCase()
                                )}
                                : {formatCurrency(Number(servicePackage.valor))}
                              </AlertTitle>
                              {servicePackage.tipospagamentos &&
                                servicePackage.tipospagamentos !== "" &&
                                servicePackage.tipospagamentos}
                            </Alert>
                          </Grid>
                        </>
                      )}

                      {clinic && clinic.info_paciente !== "" && (
                        <>
                          <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Typography
                              fontSize={16}
                              sx={{
                                color: (theme) =>
                                  theme.palette.onPrimaryContainer.main,
                                fontWeight: 500,
                                mb: 1,
                              }}
                            >
                              Informações ao paciente
                            </Typography>
                            <Alert color="error" icon={false}>
                              {clinic.info_paciente}
                            </Alert>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container mt={6}>
          {account === 0 && user && serviceGroup ? (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                startIcon={<CalendarBlank />}
                fullWidth
                variant="contained"
                disabled={isCreatingAppointment}
                size="large"
                onClick={() => {
                  handleConfirm();
                }}
                color="secondary"
              >
                {isCreatingAppointment
                  ? `Aguarde! processando solicitação`
                  : `Continuar para o agendamento`}
              </Button>
            </Grid>
          ) : account === 1 && dependent && serviceGroup ? (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                startIcon={<CalendarBlank />}
                fullWidth
                disabled={isCreatingAppointment}
                onClick={() => {
                  handleConfirm();
                }}
                variant="contained"
                size="large"
                color="secondary"
              >
                {isCreatingAppointment
                  ? `Aguarde! processando solicitação`
                  : `Continuar para o agendamento`}
              </Button>
            </Grid>
          ) : (
            serviceGroup && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                  startIcon={<CalendarBlank />}
                  fullWidth
                  disabled={true}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  {isCreatingAppointment
                    ? `Aguarde! processando solicitação`
                    : `Continuar para o agendamento`}
                </Button>
              </Grid>
            )
          )}
        </Grid>
      </Grid>

      <RegisterDependentModal
        open={registerUser}
        onClose={() => setRegisterUser(false)}
      />
    </Grid>
  );
};
