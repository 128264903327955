import {
  Alert,
  AlertTitle,
  AppBar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  StepIcon,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { CaretLeft } from "phosphor-react";
import { FormEvent, useState } from "react";
import ReactGA from "react-ga";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import LogoSection from "../../../components/LogoSection";
import { useAppointment } from "../../../hooks/useAppointment";
import { useAuth } from "../../../hooks/useAuth";
import { MainContainer } from "../../../layout/MainContainer";
import { api } from "../../../services/api";
import { useApplicationId } from "../../../store/user";
import { maskCpf, maskPhoneNumber, removeMaskCpf } from "../../../utils/masks";
import { singularizeWithArticle, validateCpf } from "../../../utils/roles";

const USER_DOCUMENT_MAX_LENGTH = 11;
const PHONE_NUMBER_MAX_LENGTH = 11;

export default function Register() {
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down("md"));
  const [searchparams] = useSearchParams();
  const navigate = useNavigate();
  const { data: applicationId } = useApplicationId();
  const { professional, serviceItem, serviceGroup, servicePackage } =
    useAppointment();

  const { signIn, isLoading } = useAuth();

  // data register
  const [name, setName] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);

  // errors messages
  const [nameError, setNameError] = useState<string>(" ");
  const [cpfError, setCpfError] = useState<string>(" ");
  const [phoneError, setPhoneError] = useState<string>(" ");
  const [birthDateError, setBirthDateError] = useState<string>(" ");
  const [emailError, setEmailError] = useState<string>(" ");
  const [genderError, setGenderError] = useState<string>(" ");
  const [passwordError, setPasswordError] = useState<string>(" ");
  const [repeatPasswordError, setRepeatPasswordError] = useState<string>(" ");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setProcessing(true);
    setNameError(" ");
    setCpfError(" ");
    setPhoneError(" ");
    setBirthDateError(" ");
    setEmailError(" ");
    setGenderError(" ");
    setPasswordError(" ");
    setRepeatPasswordError(" ");
    if (name === "") {
      setNameError("O nome é obrigatório");
      setProcessing(false);
      return;
    }
    if (cpf === "") {
      setCpfError("O CPF é obrigatório");
      setProcessing(false);
      return;
    }
    if (!validateCpf(cpf)) {
      setCpfError("Digite um CPF válido");
      setProcessing(false);
      return;
    }
    if (!birthDate) {
      setBirthDateError("A data de nascimento é orbigatório");
      setProcessing(false);
      return;
    }
    if (email === "") {
      setEmailError("O e-mail é obrigatório");
      setProcessing(false);
      return;
    }
    if (gender === "") {
      setGenderError("Selecione uma opção");
      setProcessing(false);
      return;
    }
    if (password === "") {
      setPasswordError("A senha é obrigatório");
      setProcessing(false);
      return;
    }
    if (repeatPassword !== password) {
      setRepeatPasswordError("As senhas são diferentes");
      setProcessing(false);
      return;
    }

    try {
      const res = await api.post(`/users-patients/signup`, {
        nomepaciente: name,
        sexo: gender,
        cpf: cpf,
        datanascimento: format(birthDate, "yyyy-MM-dd"),
        telefonecelular: phone,
        telefoneresidencial: "",
        idcanalatendimento: applicationId?.idcanalatendimento, // serviceChannelId,
        idcanalorigem: applicationId?.idcanalorigem, // originChannelId,
        descricaopacientefonte: "Agendamento Web",
        email: email,
        senha: password,
      });

      ReactGA.event({
        category: "agendamento-web",
        action: "registration",
        label: "registro",
      });

      setProcessing(false);
      if (res.data) {
        toast.success("Cadastro realizado com sucesso", {
          position: "top-right",
        });
        const result = await signIn(email, password);
        if (result) {
          if (professional) {
            return navigate(-1);
          }
          if (servicePackage) {
            return navigate(-1);
          }
          if (searchparams.get("redirect")) {
            navigate(searchparams.get("redirect") || "/", {
              replace: true,
            });
          } else {
            navigate("/paciente/dashboard", {
              replace: true,
            });
          }
        } else {
          navigate("/login", { replace: true });
        }
      }
    } catch (error: any) {
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setProcessing(false);
    }
  };

  const onChangeUserDocument = (value: string) => {
    value = removeMaskCpf(value);
    const regex = /^([0-9.-]+)+$/;
    if (value.length > USER_DOCUMENT_MAX_LENGTH) {
      return;
    }
    setCpf(value);
    setCpfError(" ");

    if (!value) {
      return setCpfError("O CPF é obrigatório");
    }

    if (!validateCpf(value)) {
      return setCpfError("O CPF é inválido");
    }

    if (!regex.test(value)) {
      return setCpfError("Revise este dado");
    }
  };

  const onChangePhoneNumber = (value: string) => {
    value = removeMaskCpf(value);
    const regex = /^([0-9.-]+)+$/;
    if (value.length > PHONE_NUMBER_MAX_LENGTH) {
      return;
    }
    setPhone(value);
    setPhoneError(" ");

    if (!value) {
      return setPhoneError("O número de telefone é obrigatório");
    }

    if (!regex.test(value)) {
      return setPhoneError("Revise este dado");
    }
  };

  return (
    <>
      <MainContainer theme={theme}>
        <AppBar
          position="relative"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.primary.main,
          }}
        >
          <Container maxWidth="lg">
            <Toolbar
              disableGutters
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {!matchSM && (
                <Button
                  color="inherit"
                  sx={{
                    position: "absolute",
                    left: 0,
                    color: theme.palette.onPrimary.main,
                  }}
                  onClick={() => navigate(-1)}
                  startIcon={
                    <CaretLeft size={16} color={theme.palette.onPrimary.main} />
                  }
                >
                  Voltar
                </Button>
              )}
              <LogoSection />
            </Toolbar>
          </Container>
        </AppBar>
        <Container maxWidth="lg" onSubmit={handleSubmit} component="form">
          {professional && serviceGroup && serviceItem ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              display="flex"
              mb={4}
              mt={4}
            >
              <Grid
                item
                lg={6}
                md={8}
                sm={12}
                xs={12}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Alert
                  severity="info"
                  sx={{
                    width: "100%",
                  }}
                  icon={false}
                >
                  <AlertTitle>
                    Você está agendando{" "}
                    {singularizeWithArticle(serviceGroup.nome, true)} de{" "}
                    {serviceItem.nome}
                  </AlertTitle>
                  Finalize seu cadastro para continuar
                </Alert>
              </Grid>
            </Grid>
          ) : (
            servicePackage &&
            serviceGroup && (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                display="flex"
                mb={4}
                mt={4}
              >
                <Grid
                  item
                  lg={6}
                  md={8}
                  sm={12}
                  xs={12}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Alert
                    severity="info"
                    sx={{
                      width: "100%",
                    }}
                    icon={false}
                  >
                    <AlertTitle>
                      Você está agendando combo de{" "}
                      <b>{servicePackage.titulo.toLowerCase()}</b>
                    </AlertTitle>
                    Finalize seu cadastro para continuar
                  </Alert>
                </Grid>
              </Grid>
            )
          )}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
            mt={2}
          >
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <StepIcon icon="1" active />
                        <Typography
                          component="h1"
                          variant="h6"
                          fontWeight={600}
                          sx={{
                            color: (theme) => theme.palette.text.primary,
                            fontSize: 18,
                          }}
                        >
                          Dados pessoais
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={4}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        placeholder="Digite seu nome completo"
                        label="Nome completo"
                        fullWidth
                        value={name}
                        error={nameError !== " "}
                        helperText={nameError}
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        variant="outlined"
                        size="medium"
                        placeholder="Digite seu CPF"
                        label="CPF"
                        fullWidth
                        value={maskCpf(cpf)}
                        error={cpfError !== " "}
                        helperText={cpfError}
                        type="tel"
                        onChange={(e) => onChangeUserDocument(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <DatePicker
                        label="Data de nascimento"
                        onChange={(newValue) => setBirthDate(newValue)}
                        value={birthDate}
                        disableFuture
                        sx={{
                          width: "100%",
                        }}
                      />
                      {birthDateError !== " " && (
                        <FormHelperText error>{birthDateError}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Gênero
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          onChange={(e, value) => setGender(value)}
                        >
                          <FormControlLabel
                            value="Masculino"
                            control={<Radio />}
                            label="Masculino"
                          />
                          <FormControlLabel
                            value="Feminino"
                            control={<Radio />}
                            label="Feminino"
                          />
                        </RadioGroup>
                        {genderError !== " " && (
                          <FormHelperText error>{genderError}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
            mt={2}
          >
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <StepIcon icon="2" />
                        <Typography
                          component="h1"
                          variant="h6"
                          fontWeight={600}
                          sx={{
                            color: (theme) => theme.palette.text.primary,
                            fontSize: 18,
                          }}
                        >
                          Contato
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} mt={4}>
                      <TextField
                        id="email"
                        name="email"
                        label="E-mail"
                        size="medium"
                        placeholder="Digite seu e-mail"
                        fullWidth
                        value={email}
                        error={emailError !== " "}
                        helperText={emailError}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Número de telefone"
                        size="medium"
                        placeholder="(91) 00000-0000"
                        fullWidth
                        value={maskPhoneNumber(phone)}
                        type="tel"
                        onChange={(e) => onChangePhoneNumber(e.target.value)}
                        error={phoneError !== " "}
                        helperText={phoneError}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
            mt={2}
          >
            <Grid item lg={6} md={8} sm={12} xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <StepIcon icon="2" />
                        <Typography
                          component="h1"
                          variant="h6"
                          fontWeight={600}
                          sx={{
                            color: (theme) => theme.palette.text.primary,
                            fontSize: 18,
                          }}
                        >
                          Segurança
                        </Typography>
                      </Stack>
                      <Alert
                        severity="info"
                        sx={{
                          width: "100%",
                          mt: 1,
                        }}
                        icon={false}
                      >
                        <AlertTitle>Informações sobre a senha:</AlertTitle>
                        Conter, no mínimo 6 caracteres
                      </Alert>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} mt={4}>
                      <TextField
                        id="password"
                        name="password"
                        label="Senha"
                        size="medium"
                        placeholder="Digite uma senha"
                        fullWidth
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={passwordError !== " "}
                        helperText={passwordError}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        id="password"
                        name="password"
                        label="Repita sua senha"
                        size="medium"
                        placeholder="Repita sua senha"
                        fullWidth
                        type="password"
                        autoComplete="current-password"
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        error={repeatPasswordError !== " "}
                        helperText={repeatPasswordError}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Grid item lg={6} md={8} sm={12} xs={12} mt={4}>
              <Button
                disabled={isLoading || processing}
                variant="contained"
                size="large"
                fullWidth
                type="submit"
              >
                {isLoading || processing ? (
                  <CircularProgress color="inherit" size={26} />
                ) : (
                  `Cadastrar`
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
            pb={4}
          >
            <Grid item lg={5} md={8} sm={12} xs={12} mt={2}>
              <Button
                variant="text"
                size="large"
                fullWidth
                component="a"
                href="#"
              >
                Já tenho uma conta
              </Button>
            </Grid>
          </Grid>
        </Container>
      </MainContainer>
    </>
  );
}
